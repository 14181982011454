import { Slider } from "@mui/material"
import { format } from "date-fns"
import { useCallback } from "react"
import { AnimationMeta } from "./Map/AvlMap"

type Props = {
	animation: AnimationMeta
}
export const TimeSlider = ({ animation }: Props) => {
	const { startTime, animationTime, setRealTime, pause, setPause } = animation ?? {}
	const now = startTime && animationTime ? (animationTime.getTime() - startTime.getTime()) / 1000 / 60 / 60 : undefined
	const getTime = useCallback(
		(v: number) => (startTime ? new Date(startTime.getTime() + v * 60 * 60 * 1000) : undefined),
		[startTime]
	)
	if (!animationTime) return null
	return (
		<div
			style={{
				position: "absolute",
				top: "2em",
				left: "50%",
				transform: "translate(-50%)",
				width: "50%",
				zIndex: 2,
			}}
		>
			<Slider
				value={now}
				onChange={(e, v) => setRealTime(getTime(v as number)!)}
				marks={[{ value: now!, label: <span style={{ fontSize: "2em" }}>{format(animationTime, "HH:mm")}</span> }]}
				step={1 / 60}
				style={{ width: "calc(100% - 6em)" }}
				max={24}
			/>
			<button
				style={{
					border: 0,
					lineHeight: 0,
					padding: 0,
					fontSize: "3em",
					borderRadius: "500px",
					cursor: "pointer",
				}}
				onClick={() => setPause(!pause)}
			>
				{pause ? "⏵" : "⏸"}
			</button>
		</div>
	)
}
