import { useEffect, useState } from "react"
import axios from "axios"
import { IconLayer } from "deck.gl"
import { dispatch } from "../reducers/RootReducer"
import { actions as popupActions } from "../reducers/PopupReducer"
import { LatLng } from "../utils"

export type HwyCam = {
	id: number
	name: string
	description: string
	direction: string
}
export type CameraMarkerProps = {
	cameras: HwyCam[]
	position: LatLng
	icon?: {
		rotation: number
		faceRight: boolean
	}
}

const compassOrientations = {
	W: { faceRight: true, rotation: 0 },
	N: { faceRight: true, rotation: 90 },
	S: { faceRight: false, rotation: -90 },
	E: { faceRight: false, rotation: 0 },
}

type MoTIHwyCam = [number, string, string, string, string, number, number]
const getWebcams = async () => {
	const data = await axios.get<MoTIHwyCam[]>("https://drivebc.ca/data/webcams.json")
	const cameraData = data.data.map(([id, name, description, , direction, lat, lng]) => ({
		id,
		name,
		description,
		direction,
		position: { lat, lng },
	}))
	// .filter(({ position: { lat, lng } }) => lat >= 53 && lat <= 56 && lng >= -131 && lng <= -127.5)

	const cameras: CameraMarkerProps[] = []
	cameraData.forEach(i => {
		const match = cameras.find(j => j.position.lat === i.position.lat && j.position.lng === i.position.lng)
		if (match) {
			match.cameras.push(i)
		} else {
			cameras.push({
				cameras: [i],
				position: i.position,
			})
		}
	})
	cameras.forEach(i => {
		if (i.cameras.length === 1) {
			i.icon = compassOrientations[i.cameras[0].direction as keyof typeof compassOrientations]
		}
	})
	return cameras
}

export const useHwyCamLayer = (visible: boolean) => {
	const [cameras, setCameras] = useState<CameraMarkerProps[]>([])

	useEffect(() => {
		getWebcams().then(setCameras)
	}, [])

	return new IconLayer<CameraMarkerProps>({
		id: "hwy-cam",
		data: cameras,
		visible,
		getAngle: ({ icon }) => icon?.rotation ?? 0,
		getIcon: ({ icon }) => ({
			url: `/icons/hwycam-${icon?.faceRight ? "right" : "left"}.svg`,
			width: 289,
			height: 256,
			mask: true,
		}),
		getPosition: ({ position: { lng, lat } }) => [lng, lat],
		getSize: 36,
		// getColor: [255, 133, 204, 160],
		getColor: [16, 118, 187],
		pickable: true,
		onClick: ({ object }) => dispatch(popupActions.setCameras(object.cameras)),
		autoHighlight: true,
	})
}
