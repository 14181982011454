import { useMemo, useState } from "react"
import { addDays, format } from "date-fns"
import { dispatch } from "../reducers/RootReducer"
import { actions } from "../reducers/PopupReducer"
import { IconLayer } from "deck.gl"
import { RoadJson, useRoadNetwork } from "./useRoadNetwork"
import { useSCRs } from "./useSCRs"
import { group } from "d3-array"
import { ScrType } from "../components/getTooltip"

type Props = {
	scrs: ScrType[]
}

export const ScrDialog = ({ scrs }: Props) => {
	const [index, setIndex] = useState(0)
	return useMemo(() => {
		const scr = scrs[index]
		return (
			<div style={{ fontSize: "1.2em", margin: "1em" }}>
				<h2 style={{ textAlign: "center", marginTop: 0, fontSize: "1em" }}>
					<span style={{ fontSize: "smaller" }}>SCR {scr.ActionItemId}</span>
					<br />
					<span style={{ fontSize: "smaller" }}>
						Last updated {format(new Date(scr.LastModifiedAt.substr(0, scr.LastModifiedAt.length - 1)), "cccc p")}
					</span>
					<br />
					{scr.Subject}
				</h2>
				<p style={{ textAlign: "center" }}>
					{scr.StakeholderName} - {scr.StakeholderPhone} - {scr.Road_display!}
				</p>
				{scr.IssueDescription.split("\n").map((s, i) => (
					<p key={i} style={{ maxWidth: "24em", margin: "auto" }}>
						{s}
					</p>
				))}
				{scrs.length > 1 && (
					<ol style={{ listStyle: "none", textAlign: "center", margin: 0, padding: 0 }}>
						{scrs.map((v, i) => (
							<li key={v.ActionItemId} style={{ display: "inline" }}>
								{scr === scrs[i] ? (
									<span
										style={{ fontSize: "smaller", lineHeight: "1em", margin: "0 0.5em", fontWeight: "bold" }}
									>
										{i + 1}
									</span>
								) : (
									<button
										style={{
											border: 0,
											cursor: "pointer",
											backgroundColor: "inherit",
											color: "blue",
											textDecoration: "underline",
											margin: "0 0.5em",
											padding: 0,
											lineHeight: "1em",
										}}
										onClick={() => setIndex(i)}
									>
										{i + 1}
									</button>
								)}
							</li>
						))}
					</ol>
				)}
			</div>
		)
	}, [scrs, index])
}

export const useScrLayer = (visible: boolean) => {
	const scrs = useSCRs()
	const network = useRoadNetwork()
	return useMemo(() => {
		const groupByRoad = group(scrs ?? [], s => s.RoadNumber)
		const data = Array.from(groupByRoad.values()).filter(i => !!getRoad(i[0].RoadNumber ?? null, network))
		return new IconLayer<ScrType[]>({
			id: "scrs",
			data,
			visible,
			getIcon: scrs => ({
				url: `/icons/scr${Math.min(scrs.length, 3)}.svg`,
				width: 256,
				height: 256,
				mask: true,
			}),
			getSize: 48,
			getPixelOffset: [24, -24],
			getColor: scrs => {
				const latest = scrs[0]
				const old = new Date(latest.LastModifiedAt) < addDays(new Date(), -1)
				return old ? [255, 152, 0] : [244, 67, 54]
			},
			getPosition: scrs => getRoadCenter(scrs[0].RoadNumber!, network),
			pickable: true,
			onClick: ({ object }) => dispatch(actions.setScrs(object)),
			autoHighlight: true,
		})
	}, [scrs, network, visible])
}

const getRoad = (roadNumber: string | null, network: RoadJson[]) => {
	const rid = +roadNumber!
	return network.find(({ properties: { rfiHighwayName, serviceArea } }) => {
		if (serviceArea !== "26") return false
		return +rid === parseInt(rfiHighwayName.split("-").pop()!)
	})
}

const getRoadCenter = (roadNumber: string | null, network: RoadJson[]) => {
	const {
		geojson: { coordinates },
	} = getRoad(roadNumber, network)!
	return coordinates[Math.floor(coordinates.length / 2)]
}
