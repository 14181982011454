import { format as formatDate } from "date-fns"
import { Popup } from "react-map-gl"
import { actions } from "../reducers/AppReducer"
import { dispatch } from "../reducers/RootReducer"
import { Asset } from "../hooks/useAssets"

type VehicleDialogProps = {
	position: [number, number]
	asset: Asset
	datetime: Date
	speed: number | null
	unitController?: boolean
	offsetTop?: number
}
export const VehicleDialog = ({ position, asset, datetime, speed, offsetTop, unitController }: VehicleDialogProps) => {
	const handleClose = () => unitController && dispatch(actions.setActiveAsset(undefined))
	return (
		<Popup latitude={position[1]} longitude={position[0]} offsetTop={offsetTop} onClose={handleClose}>
			<div style={{ textAlign: "center" }}>
				<b>{asset.assetCode}</b>
				<br />
				{formatDate(datetime, "EEE HH:mm")}
				{speed ? (
					<>
						<br />
						{speed.toFixed(0)} kph
					</>
				) : undefined}
			</div>
		</Popup>
	)
}
