import { Dispatch, memo, SetStateAction } from "react"
import { ScaleLinear } from "d3-scale"
import { ROW_HEIGHT, ROW_BUFFER, AXIS_HEIGHT, Period } from "./Timeline"
import { TooltipProps } from "./Tooltip"

export type Props = {
	rfi: string
	i: number
	periods: Period[]
	scale: ScaleLinear<number, number, never>
	setHover: Dispatch<SetStateAction<TooltipProps | null>>
}
export const TimelineRow = memo(function TimelineRow({ rfi, i, periods, scale, setHover }: Props) {
	return (
		<g key={rfi} transform={`translate(0 ${i * (ROW_HEIGHT + ROW_BUFFER) + AXIS_HEIGHT})`}>
			<text x={0} y={ROW_HEIGHT / 2} dominantBaseline="middle">
				{rfi}
			</text>
			{periods.map(period => {
				const { start, end, assetCodes } = period
				const x1 = scale(start)
				const x2 = scale(end)
				return (
					<rect
						key={start}
						x={x1}
						width={Math.max(1, x2 - x1)}
						height={ROW_HEIGHT}
						fill="black"
						onMouseMove={e =>
							setHover({
								x: e.pageX,
								y: e.pageY,
								start: new Date(start),
								end: new Date(end),
								assets: Array.from(assetCodes.values()),
								period,
							})
						}
						onMouseOut={e => setHover(hover => (hover?.period === period ? null : hover))}
					/>
				)
			})}
		</g>
	)
})
