import { useParams } from "react-router-dom"
import { nowAgeLegend, ageLegend, summerClassLegend, winterClassLegend } from "./LegendLineEntry"
import { MenuSection } from "./MenuSection"
import { ParamsProps } from "./Navbar"

export const Legend = () => {
	const { mode, group } = useParams<ParamsProps>()
	return (
		<MenuSection title="Legend" defaultOpen>
			{mode === "due" && nowAgeLegend}
			{["age", "due"].includes(mode!) && ageLegend}
			{group === "summer" && summerClassLegend}
			{group === "winter" && winterClassLegend}
		</MenuSection>
	)
}
