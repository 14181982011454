import { Reading } from "./RwisContent"

type Props = {
	current: Reading
}
export const RwisWindHumidity = ({ current }: Props) => {
	if (!current.humidity) return null
	return (
		<p>
			{current.humidity}% humidity
			{current.wind_mean !== null &&
				current.wind_mean !== undefined &&
				` / ${Math.round(current.wind_mean)} km/h winds`}
		</p>
	)
}
