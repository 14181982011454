import { useMemo } from "react"
import { Station } from "./RwisContent"

export const useRange = (station?: Station) =>
	useMemo(() => {
		if (!station) return
		const dataPoints = [
			...station.readings.flatMap(i => [i.air_temp, i.surface_temp, i.dew_point, i.snow_new, i.precip_new]),
			...(station.latestForecast?.targets.map(i => i.surface_temp) ?? []),
		].filter(i => i !== null && i !== undefined) as number[]
		const min = Math.floor(Math.min(...dataPoints) / 5) * 5
		return [min, min + 20] as [number, number]
	}, [station])
