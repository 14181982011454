import { useEffect } from "react"
import { useSelector, dispatch } from "../../reducers/RootReducer"
import { actions } from "../../reducers/AppReducer"
import { Credentials, credentialsKey } from "../../AvlDatabase"
import { LoginForm } from "./LoginForm"
import { msal, handleAuthenticationResult } from "./msal"
import App from "../../App"

export const scopes: string[] = []
export const LoginExperience = () => {
	const session = useSelector(state => state.app.session)

	useEffect(() => {
		// if a user is already laoded, done
		if (session?.user) return

		// checks if we are coming back from a Microsoft login
		const handleLogin = async () => {
			const result = await msal.handleRedirectPromise()
			const credentials = await handleAuthenticationResult(result)
			credentials !== undefined && dispatch(actions.login(credentials))
		}

		// check cache for a saved user
		const credentials = localStorage.getItem(credentialsKey)
		if (credentials) {
			const cachedCredentials = JSON.parse(credentials) as Credentials
			dispatch(actions.login(cachedCredentials))
			return
		} else {
			dispatch(actions.login(null))
		}
		handleLogin()
	}, [session])

	if (session === undefined) return null
	if (session === null) return <LoginForm />
	return <App />
}
