import { ButtonGroup, Button } from "@mui/material"

export enum VehicleType {
	ANY = "any",
	PLOW = "Plow",
}

export type Props = {
	vehicleType: VehicleType
	setVehicleType: (vehicleType: VehicleType) => void
}

export const VehicleTypeChooser = ({ vehicleType, setVehicleType }: Props) => {
	return (
		<ButtonGroup variant="contained" color="primary">
			<Button
				onClick={() => setVehicleType(VehicleType.ANY)}
				variant={vehicleType === VehicleType.ANY ? "contained" : "outlined"}
			>
				Any
			</Button>
			<Button
				onClick={() => setVehicleType(VehicleType.PLOW)}
				variant={vehicleType === VehicleType.PLOW ? "contained" : "outlined"}
			>
				Plow
			</Button>
		</ButtonGroup>
	)
}
