import { useMemo } from "react"
import proj4 from "proj4"
import { useRoadsQuery } from "../graphql"

const epsg3005Definition =
	"+proj=aea +lat_1=50 +lat_2=58.5 +lat_0=45 +lon_0=-126 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
const gps3005 = proj4(epsg3005Definition)

export type RoadJson = {
	geojson: { coordinates: [number, number][] }
	properties: {
		id: number
		bus: boolean | null
		summerClass: string
		winterClass: string
		rfiHighwayName: string
		rfiHighwayDescription: string
		serviceArea: string
	}
}

export const useRoadNetwork = () => {
	const { data: roadData } = useRoadsQuery({
		fetchPolicy: "cache-first",
		pollInterval: 24 * 60 * 60 * 1000,
	})

	return useMemo(
		() =>
			roadData?.imapRoadClassFeatures?.map(({ roadClass, roadFeature }) => {
				const { id, bus, summerClass, winterClass, geom } = roadClass!
				const { geojson } = geom!
				const coordinates = geojson.coordinates.map((i: [number, number]) => gps3005.inverse(i))
				const { rfiHighwayName, rfiHighwayDescription, serviceArea } = roadFeature!
				return {
					geojson: { ...geojson, coordinates },
					properties: {
						id,
						bus,
						summerClass,
						winterClass,
						rfiHighwayName,
						rfiHighwayDescription,
						serviceArea,
					},
				} as RoadJson
			}) ?? [],
		[roadData]
	)
}
