import { useEffect, useState } from "react"

export const useGps = () => {
	const [position, setPosition] = useState<GeolocationPosition | null>()

	useEffect(() => {
		const tracker = navigator.geolocation.watchPosition(
			setPosition,
			e => {
				console.error(e)
				alert(`Could not load GPS data: ${e.message}`)
				setPosition(null)
			},
			{
				enableHighAccuracy: true,
				maximumAge: 500,
			}
		)
		return () => {
			tracker && navigator.geolocation.clearWatch(tracker)
		}
	}, [])

	return position
}
