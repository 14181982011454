import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { Fab, Drawer, Zoom, CssBaseline } from "@mui/material"
import { Menu } from "@mui/icons-material"
import { LocalizationProvider } from "@mui/lab"
import dateAdapter from "@mui/lab/AdapterDateFns"

import { GraphProvider } from "./reducers/GraphContext"
import { useSelector, dispatch } from "./reducers/RootReducer"
import { actions } from "./reducers/AppReducer"

import { AvlMap } from "./components/Map/AvlMap"

import { Navbar } from "./components/Navbar/Navbar"
import { Popup } from "./components/Popup"
import { touchUi } from "./utils"
import { useYard } from "./maplayers/useInfrastructure"
import { PatrolTimeline } from "./patrolTimeline/PatrolTimeline"
import "./App.css"

const MainView = () => {
	const menuOpen = useSelector(state => state.app.menuOpen)
	const closeMenu = () => dispatch(actions.setMenu(false))
	const toggleMenu = () => dispatch(actions.toggleMenu())

	return (
		<>
			<Drawer
				variant={touchUi ? "temporary" : "permanent"}
				open={!touchUi || menuOpen}
				anchor="left"
				onClose={closeMenu}
			>
				<Navbar />
			</Drawer>
			<AvlMap />
			<Zoom in={!menuOpen}>
				<Fab color="primary" onClick={toggleMenu} style={{ position: "absolute", bottom: "1em", left: "1em" }}>
					<Menu />
				</Fab>
			</Zoom>
			<Popup />
		</>
	)
}

const YardRedirector = () => {
	const homeYardId = 1 // useSelector(state => state.app.session!.user.home_yard_id)
	const yard = useYard(homeYardId)
	return <Navigate to={yard ? `/yard/${yard.shortName}/age/all` : "/area/07/age/all"} />
}

const App = () => {
	return (
		<LocalizationProvider dateAdapter={dateAdapter}>
			<GraphProvider>
				<BrowserRouter>
					<CssBaseline />
					<Routes>
						<Route path="/patrols" element={<PatrolTimeline />} />
						<Route path="/area/:area/:mode/:group" element={<MainView />} />
						<Route path="/yard/:yard/:mode/:group" element={<MainView />} />
						<Route path="/" element={<YardRedirector />} />
					</Routes>
				</BrowserRouter>
			</GraphProvider>
		</LocalizationProvider>
	)
}

export default App
