import { useEffect, useMemo, useRef, useState } from "react"
import { RGBAColor, TripsLayer } from "deck.gl"
import { useBreadcrumbs } from "../hooks/useBreadcrumbs"
import { Asset, useAssets } from "../hooks/useAssets"

type Patrol = {
	asset: Asset
	geometry: [number, number][]
	datetimes: number[]
}

export const useTrips = () => {
	const assets = useAssets()
	const breadcrumbs = useBreadcrumbs()
	return useMemo(() => {
		if (!assets) return
		return Array.from(
			[...breadcrumbs.sort((a, b) => b.datetime.getTime() - a.datetime.getTime())]
				.reduce((map, { assetId: id, position, datetime }) => {
					if (!map.has(id)) map.set(id, { asset: assets.get(id)!, geometry: [], datetimes: [] })
					const record = map.get(id)!
					record.geometry.push(position)
					record.datetimes.push(datetime.getTime() / 1000)
					return map
				}, new Map<number, Patrol>())
				.values()
		)
	}, [breadcrumbs, assets])
}

export const assetColors = {
	Pickup: [72, 201, 245],
	"Manager Pickup": [72, 201, 245],
	"Single Axle Truck": [157, 245, 59],
	"Tandem Axle Truck": [157, 245, 59],
	"Tridam Axle Truck": [157, 245, 59],
	Grader: [245, 159, 71],
	Loader: [222, 83, 245],
	Backhoe: [222, 83, 245],
	Excavator: [222, 83, 245],
} as const

export const useAnimationLayer = (trips: Patrol[] | undefined, trailLength = 1500, playRate: number) => {
	const [time, setTime] = useState(0)

	const startTime = useMemo(() => {
		return trips?.reduce((min, { datetimes }) => Math.min(min, datetimes[0]), Infinity)
	}, [trips])
	useEffect(() => setTime(0), [trips])

	const lastFrame = useRef(0)
	const frame = useRef(0)
	useEffect(() => {
		if (!trips) return
		const animate = (time: number) => {
			const msBetweenFrames = time - lastFrame.current
			lastFrame.current = time
			const newSeconds = (msBetweenFrames / 1000) * playRate
			setTime(t => (t + newSeconds) % (24 * 60 * 60))
			frame.current = window.requestAnimationFrame(animate)
		}
		frame.current = window.requestAnimationFrame(animate)
		return () => {
			window.cancelAnimationFrame(frame.current)
			// hack to try to beat race condition if frame.current changes in animate while this is happening
			window.cancelAnimationFrame(frame.current - 1)
		}
	}, [playRate, trips])

	const layer = !startTime
		? undefined
		: new TripsLayer({
				id: `trips-${trailLength}`,
				data: trips,
				getPath: d => d.geometry,
				getTimestamps: d => d.datetimes.map(i => i - startTime),
				getColor: i => assetColors[(i.asset.category ?? "Pickup") as keyof typeof assetColors] as any as RGBAColor,
				opacity: 0.3,
				widthMinPixels: 15,
				capRounded: true,
				jointRounded: true,
				trailLength,
				currentTime: time,
		  })
	return { time, setTime, startTime, layer }
}
