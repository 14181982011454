import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Credentials } from "../AvlDatabase"
import { Asset } from "../hooks/useAssets"

export type Tooltip = {
	x: number
	y: number
	text: string
}
export type AppState = {
	session?: Credentials | null
	menuOpen: boolean
	newMenu: boolean
	activeAsset?: Asset
	centerOnUser: boolean
	tooltip?: Tooltip
}

export const initialState: AppState = {
	session: undefined,
	menuOpen: true,
	newMenu: true,
	centerOnUser: false,
}

export const { reducer, actions } = createSlice({
	name: "avl",
	initialState,
	reducers: {
		update: (state, { payload }: PayloadAction<Partial<AppState>>) => ({
			...state,
			...payload,
		}),
		setMenu: (state, { payload }: PayloadAction<boolean>) => ({
			...state,
			menuOpen: payload,
			newMenu: false,
		}),
		setTooltip: (state, { payload }: PayloadAction<Tooltip | undefined>) => {
			state.tooltip = payload
		},
		setActiveAsset: (state, { payload }: PayloadAction<Asset | undefined>) => {
			state.activeAsset = payload
		},
		centerOnUser: (state, { payload }: PayloadAction<boolean>) => {
			state.centerOnUser = payload
		},
		toggleMenu: state => {
			state.menuOpen = !state.menuOpen
		},
		toggleCenterOnUser: state => {
			state.centerOnUser = !state.centerOnUser
		},
		login: (state, { payload }: PayloadAction<Credentials | null>) => {
			state.session = payload
		},
		logout: state => {
			state.session = null
		},
	},
})
