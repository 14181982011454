import { format } from "date-fns"
import { Period } from "./Timeline"

export type TooltipProps = {
	x: number
	y: number
	start: Date
	end: Date
	assets: string[]
	period: Period
}
export const Tooltip = ({ x, y, assets, start, end }: TooltipProps) => {
	return (
		<div
			style={{
				position: "absolute",
				top: y + 2,
				left: x + 2,
				backgroundColor: "white",
				border: "2px solid black",
				display: "inline-block",
				padding: "0.25em 0.5em",
				textAlign: "center",
			}}
		>
			<p style={{ margin: 0, fontWeight: "bold" }}>
				{format(start, "HH:mm")}-{format(end, "HH:mm")}
			</p>
			{assets.map(i => (
				<p key={i} style={{ margin: 0 }}>
					{i}
				</p>
			))}
		</div>
	)
}
