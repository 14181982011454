import { useMemo } from "react"
import { useInfrastructureQuery } from "../graphql"
import proj4 from "proj4"
import { IconLayer, PathLayer } from "deck.gl"

export const epsg3005Definition =
	"+proj=aea +lat_1=50 +lat_2=58.5 +lat_0=45 +lon_0=-126 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
export const gps3005 = proj4(epsg3005Definition)

export const useInfrastructure = () => {
	return useInfrastructureQuery({
		fetchPolicy: "cache-first",
		pollInterval: 20 * 60 * 60 * 1000,
		variables: { serviceAreas: ["407", "420", "426"] },
	})
}

export type RoadStructure = {
	id: number
	serviceArea: string
	structureName: string
	structureCode: string
	structureType: string
	coordinates: [number, number][]
}
export const useStructures = () => {
	const { data } = useInfrastructure()
	return useMemo(
		() =>
			data?.imapRoadStructures?.map(
				({ id, adminUnitCode, bmisStructureName, bmisStructureNumber, bmisStructureType, geom }) =>
					({
						id,
						serviceArea: adminUnitCode!.substr(1),
						structureName: bmisStructureName,
						structureCode: bmisStructureNumber,
						structureType: bmisStructureType,
						coordinates: geom!.geojson.coordinates.map((i: [number, number]) => gps3005.inverse(i)),
					} as RoadStructure)
			),
		[data?.imapRoadStructures]
	)
}
export const useStructureLayer = () => {
	const data = useStructures()
	return useMemo(
		() =>
			new PathLayer<RoadStructure>({
				id: "structures",
				data,
				widthMinPixels: 10,
				widthMaxPixels: 80,
				getWidth: 100,
				getPath: ({ coordinates }) => coordinates,
				getColor: [255, 80, 80],
				pickable: true,
				autoHighlight: true,
			}),
		[data]
	)
}

export type RestArea = {
	id: number
	serviceArea: string
	restAreaName: string
	restAreaClass: string
	restAreaNumber: string
	position: [number, number]
}
export const useRestareas = () => {
	const { data } = useInfrastructure()
	return useMemo(
		() =>
			data?.imapRestareas?.map(
				({ __typename, adminUnitCode, geom, ...props }) =>
					({
						...props,
						serviceArea: adminUnitCode!.substr(1),
						position: gps3005.inverse([geom!.x, geom!.y]),
					} as RestArea)
			),
		[data?.imapRestareas]
	)
}

export const useRestareaLayer = (visible: boolean) => {
	const data = useRestareas()
	return useMemo(
		() =>
			new IconLayer<RestArea>({
				id: "restareas",
				data,
				visible,
				// getColor: [127, 127, 127],
				getPosition: ({ position }) => position,
				getSize: 60,
				getIcon: () => ({
					url: "/icons/restarea.svg",
					width: 480,
					height: 480,
					// mask: true,
				}),
				pickable: true,
				autoHighlight: true,
			}),
		[data, visible]
	)
}

export const useYards = () => {
	const { data } = useInfrastructure()
	return useMemo(() => {
		return data?.opsYards?.map(yard => ({
			...yard,
			shortName: yard.description.split(" ").pop()!.toLowerCase(),
		}))
	}, [data?.opsYards])
}
export const useYard = (id: number) => {
	const yards = useYards()
	return yards?.find(i => i.id === id)
}
