import { useMemo } from "react"
import { ChartDataset } from "chart.js"
import { getSeries } from "./getSeries"
import { Forecast, Reading } from "./RwisContent"

export const useDatasets = (readings?: Reading[], latestForecast?: Forecast) => {
	return useMemo(() => {
		if (!readings) return
		const forecastTemp = latestForecast?.targets.map(i => ({ x: i.target_time, y: i.surface_temp }))
		return [
			getSeries("Air Temp", false, "temperature", "rgb(26,84,190)", readings, "air_temp"),
			getSeries("Pavement Temp", false, "temperature", "rgb(244,51,7)", readings, "surface_temp"),
			getSeries("Dew Point", true, "temperature", "rgba(0,0,0,0)", readings, "dew_point", "rgba(0,0,0,0.15)"),
			forecastTemp && {
				label: "Forecasted Air Temp",
				fill: false,
				yAxisID: "temperature",
				borderColor: "rgb(89, 147, 255)",
				borderDash: [8, 5],
				data: forecastTemp,
			},
			getSeries("New Snow", false, "accumulation", "rgb(0,127,0)", readings, "snow_new"),
			getSeries("Precip", false, "accumulation", "rgba(255,196,0,1)", readings, "precip_new"),
		].filter(i => i!) as ChartDataset<"line">[]
	}, [readings, latestForecast])
}
