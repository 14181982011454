import { useDispatch as useReduxDispatch, createSelectorHook } from "react-redux"
import { configureStore, combineReducers, Action, ThunkAction } from "@reduxjs/toolkit"

import { reducer as appReducer } from "./AppReducer"
import { reducer as commentReducer } from "./CommentReducer"
import { reducer as popupReducer } from "./PopupReducer"
import { reducer as layersReducer } from "./LayersReducer"

export const reducer = combineReducers({
	app: appReducer,
	comments: commentReducer,
	popup: popupReducer,
	layers: layersReducer,
})

export type RootState = ReturnType<typeof reducer>
export const store = configureStore({ reducer })
export const { dispatch } = store
export type AppDispatch = typeof dispatch

export const useDispatch = () => useReduxDispatch<AppDispatch>()
export const useSelector = createSelectorHook<RootState>()
export type AppThunk = ThunkAction<void, RootState, undefined, Action<string>>

// const headers = { "content-type": "application/json" }
// export const login = ({ username, password }: { username: string; password: string }): AppThunk => async dispatch => {
// 	const body = JSON.stringify({ username, password })
// 	const resp = await fetch("https://avl.eag.tools/api/login", { method: "post", body, headers })
// 	const respData = await resp.json()
// 	if (!resp.ok) return alert("Error loggin in:\n" + JSON.stringify(respData, null, 2))
// 	const sessionData = {
// 		sessionid: respData.sessionid,
// 		username,
// 	}
// 	await db.session.add(sessionData)
// 	dispatch(actions.login(sessionData))
// }

// export const logout = (): AppThunk => async (dispatch, getState) => {
// 	const { session } = getState().app
// 	if (!session) return
// 	const body = JSON.stringify({ sessionid: session.sessionid })
// 	const resp = await fetch("https://avl.eag.tools/api/login", { method: "delete", body, headers })
// 	await db.session.delete(session.username)
// 	if (!resp.ok) {
// 		const respData = await resp.json()
// 		return alert("Error logging out:\n" + JSON.stringify(respData))
// 	}
// 	dispatch(actions.logout())
// }
