import { ChartDataset } from "chart.js"

export type Props = {
	datasets: ChartDataset<"line">[]
}
export const RwisChartLegend = ({ datasets }: Props) => {
	return (
		<section style={{ textTransform: "uppercase", color: "white", fontSize: "1.4em", fontWeight: "bold" }}>
			{datasets.map(i => (
				<span
					key={i.label}
					style={{
						backgroundColor: i.backgroundColor ? "grey" : (i.borderColor as string),
						padding: "0 0.4em",
						display: "inline-block",
					}}
				>
					{i.label}
				</span>
			))}
		</section>
	)
}
