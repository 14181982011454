import { useState } from "react"
import { Dialog } from "@mui/material"
import styles from "./ChangeLog.module.css"

type ChangeProps = {
	short: string
	long: string
}
export const Change = ({ short, long }: ChangeProps) => {
	const [open, setOpen] = useState(false)
	return (
		<li>
			<button onClick={() => setOpen(true)}>{short}</button>
			<Dialog maxWidth="sm" open={open} onClose={() => setOpen(false)}>
				<p style={{ margin: "1em" }}>{long}</p>
			</Dialog>
		</li>
	)
}
export const ChangeLog = () => {
	return (
		<div>
			<p>Latest improvements:</p>
			<ol className={styles.list}>
				<Change
					short="iPhone performance improvements"
					long="iPhone pan and zoom performance drastically improved"
				/>
				<Change short="Service Area 20" long="Added Service Area 20 (Robson) data and navigation shortcuts" />
				<Change short="New user accounts" long="Authentication mechanisms shared with the upcoming timecard app" />
				<Change short="DriveBC events" long="DriveBC events are plogged on the map for monitored regions" />
				<Change
					short="Tooltips"
					long="Hover-based tooltips with useful information about roads, vehicles, highway cams, etc."
				/>
			</ol>
		</div>
	)
}
