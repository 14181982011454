import { RefObject, useEffect, useState } from "react"
import styles from "./VideoViewer.module.css"

type Props = {
	src: string
	width?: number
	startTime: number
	startSpeed?: number
	encodingSpeed?: number
	prev?: () => void
	next?: () => void
}
export const VideoViewer = ({
	src,
	width = 1280,
	startTime,
	startSpeed = 1,
	encodingSpeed = 10,
	prev,
	next,
}: Props) => {
	const [video, setVideo] = useState<HTMLVideoElement | null>(null)
	const [speed, setSpeed] = useState<RefObject<number>>({ current: startSpeed / encodingSpeed })

	const height = (width * 9) / 16

	useEffect(() => {
		if (!video) return
		video.playbackRate = speed.current!
	}, [speed, video])

	const speedDown = () => setSpeed({ current: Math.max(0.1, speed.current! / 2) })
	const speedUp = () => setSpeed({ current: Math.min(6.4, speed.current! * 2) })

	const adjustTime = (seconds: number) => {
		if (!video) return
		video.currentTime += seconds / encodingSpeed
	}

	const setInitialTime = () => {
		if (!video) return
		video.currentTime = startTime / encodingSpeed
		video.play()
	}

	const takeScreenshot = () => {
		if (!video) return
		const canvas = document.createElement("canvas")
		canvas.width = width
		canvas.height = height
		const ctx = canvas.getContext("2d")!
		ctx.drawImage(video, 0, 0, width, height)
		const dataUri = canvas.toDataURL("image/jpeg")
		// window.location.href = dataUri.replace('image/jpeg', 'image/octet-stream')
		// window.location.href = dataUri

		const link = document.createElement("a")
		// should really calculate datetime from path for best user experience
		// but as a flexible viewer this shouldn't assume the filename is descriptive of time
		const seconds = video.currentTime * encodingSpeed
		link.download = `screencap-${src.substr(0, src.length - 4)}-${seconds}.jpg`
		link.href = dataUri
		// document.body.appendChild(link)
		link.click()
	}

	// useEffect(setInitialTime, [startTime])

	return (
		<div className={styles.root} style={{ width, height }}>
			<video
				ref={setVideo}
				style={{ position: "absolute" }}
				src={src}
				width={width}
				height={height}
				onLoadedMetadata={setInitialTime}
				autoPlay
				controls
			></video>
			<div className={`${styles.prominent} ${styles.timeskip}`}>
				<button onClick={() => adjustTime(-3600)}>-60</button>
				<button onClick={() => adjustTime(-600)}>-10</button>
				<button onClick={() => adjustTime(-60)}>-1</button>
				<button onClick={() => adjustTime(60)}>+1</button>
				<button onClick={() => adjustTime(600)}>+10</button>
				<button onClick={() => adjustTime(3600)}>+60</button>
			</div>
			<div className={`${styles.clean} ${styles.speed}`}>
				<button onClick={speedDown}>&laquo;</button>
				<span>{(speed.current || 1) * encodingSpeed}x</span>
				<button onClick={speedUp}>&raquo;</button>
			</div>
			{prev && (
				<button className={styles.nextPrev} onClick={prev}>
					<svg viewBox="-16 -16 528 528" width="36">
						<path
							fill="white"
							stroke="black"
							strokeWidth="16"
							d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
						/>
					</svg>
				</button>
			)}
			{next && (
				<button className={styles.nextPrev} style={{ right: 0 }} onClick={next}>
					<svg viewBox="-16 -16 528 528" width="36">
						<path
							fill="white"
							stroke="black"
							strokeWidth="16"
							d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"
						/>
					</svg>
				</button>
			)}
			<button className={styles.screenshot} onClick={takeScreenshot}>
				<svg viewBox="-16 -16 464 528" width="32">
					<path
						fill="white"
						stroke="black"
						strokeWidth="16"
						d="M128 192a32 32 0 1 0-32-32 32 32 0 0 0 32 32zM416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-32 320H64V96h320zM268.8 209.07a16 16 0 0 0-25.6 0l-49.32 65.75L173.31 244a16 16 0 0 0-26.62 0L96 320h256z"
					/>
				</svg>
			</button>
		</div>
	)
}
