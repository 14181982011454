import { useMemo } from "react"
import { rgb } from "d3-color"
import { RGBAColor } from "deck.gl"
import { useRoadNetwork } from "./useRoadNetwork"
import patrolRequirements from "./patrolRequirements.json"
import colorBins from "../components/ageColors.json"
import { useNetworkLayer } from "./useRoadLayer"
import { AgesQuery, useAgesQuery } from "../graphql"

export type PatrolAgesType = NonNullable<AgesQuery["patrolAges"]>[number]

export const usePatrolAges = () => {
	const { data } = useAgesQuery({ fetchPolicy: "no-cache", pollInterval: 60 * 1000 })
	return useMemo(() => {
		if (!data?.patrolAges) return {}
		return (data?.patrolAges).reduce((map, i) => {
			map[i.id] = i
			return map
		}, {} as Record<string, PatrolAgesType>)
	}, [data?.patrolAges])
}

export const useRoadPatrolAges = () => {
	const network = useRoadNetwork()
	const patrolAges = usePatrolAges()
	return useMemo(() => {
		return network.map(i => ({ ...i, patrolAges: patrolAges[i.properties.id] as PatrolAgesType | undefined }))
	}, [network, patrolAges])
}

export const useAgeLayer = (group: string) => {
	const data = useRoadPatrolAges()

	const getColor = useMemo(() => {
		const target = group === "all" ? "visit" : group
		return (d: typeof data[number]): RGBAColor => {
			const age = d.patrolAges?.[`${target}_age` as keyof PatrolAgesType] ?? 365 * 24 * 60 * 60
			const c = rgb(colorBins.find(({ cutoff }, i) => i === colorBins.length - 1 || cutoff * 60 * 60 > age)!.color)
			return [c.r, c.g, c.b]
		}
	}, [group])

	return useNetworkLayer(`age_${group}`, data, getColor)
}

export const useDueLayer = (scenario: string) => {
	const data = useRoadPatrolAges()

	const getColor = useMemo(() => {
		const scenarioRequirements = patrolRequirements[scenario as keyof typeof patrolRequirements]
		return ({ patrolAges, properties: { summerClass } }: typeof data[number]): RGBAColor => {
			const age = patrolAges?.patrol_age ?? 365 * 24 * 60 * 60
			const requiredResponseTime = scenarioRequirements[summerClass as keyof typeof scenarioRequirements] * 3600
			const timeRemaining = requiredResponseTime - age
			const c = rgb(
				colorBins.find(({ cutoff }, i) => i === colorBins.length - 1 || cutoff * 60 * 60 > timeRemaining)!.color
			)
			return [c.r, c.g, c.b]
		}
	}, [scenario])

	return useNetworkLayer(`due_${scenario}`, data, getColor)
}
