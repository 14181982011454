import { HistoryIcon } from "../Icons"
import { AllIcon, PickupIcon, PlowIcon, GraderIcon } from "../VehicleIcons"
import { MenuSection } from "./MenuSection"
import { NavbarLink } from "./NavbarLink"

export type Props = {
	prefix: string
}
export const PatrolDueOptions = ({ prefix }: Props) => {
	return (
		<MenuSection title="Patrol Age" prefix="age" icon={HistoryIcon}>
			<NavbarLink to={`${prefix}/age/all`} icon={AllIcon}>
				Any
			</NavbarLink>
			<NavbarLink to={`${prefix}/age/patrol`} icon={PickupIcon}>
				Patrol
			</NavbarLink>
			<NavbarLink to={`${prefix}/age/plow`} icon={PlowIcon}>
				Plow
			</NavbarLink>
			<NavbarLink to={`${prefix}/age/grader`} icon={GraderIcon}>
				Grader
			</NavbarLink>
		</MenuSection>
	)
}
