import { Dialog } from "@mui/material"
import { useSelector, dispatch } from "../reducers/RootReducer"
import { actions as popupActions } from "../reducers/PopupReducer"
import { WorkIdDialog } from "../maplayers/WorkIdLayer"
import { GpsVideo } from "./GpsVideo"
import { RwisContent } from "../maplayers/rwis/RwisContent"
import { DriveBcEvent } from "./DriveBcEvent"
import { ScrDialog } from "../maplayers/useScrLayer"
import { CameraContent } from "./CameraContent"

export const Popup = () => {
	const popup = useSelector(state => state.popup)
	if (!popup) return null
	return (
		<Dialog maxWidth="md" open onClose={() => dispatch(popupActions.clear())}>
			{popup.category === "RWIS" && <RwisContent name={popup.station.name} id={popup.station.id} />}
			{popup.category === "HighwayCams" && <CameraContent cameras={popup.cameras} />}
			{popup.category === "Dashcam" && <GpsVideo {...popup.video} />}
			{popup.category === "DriveBC" && <DriveBcEvent event={popup.event} />}
			{popup.category === "SCR" && <ScrDialog scrs={popup.scrs} />}
			{popup.category === "WorkID" && <WorkIdDialog workid={popup.workid} />}
		</Dialog>
	)
}
