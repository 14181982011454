import { useMemo } from "react"
import { scaleLinear } from "d3-scale"
import { addDays, endOfDay, format, startOfDay } from "date-fns"

export type Props = {
	domain: [number, number]
	range: [number, number]
	height: number
}

export const Axis = ({ domain, range, height }: Props) => {
	const ticks = useMemo(() => {
		const xScale = scaleLinear().domain(domain).range(range)
		let i = startOfDay(domain[0])
		const end = endOfDay(domain[1]).getTime()
		const ticks = []
		while (i.getTime() < end) {
			if (i.getTime() >= domain[0]) ticks.push({ value: format(i, "d"), xOffset: xScale(i.getTime()) })
			i = addDays(i, 1)
		}
		return ticks
	}, [domain, range])

	return (
		<svg>
			{ticks.map(({ value, xOffset }) => (
				<g key={`${value}-${xOffset}`} transform={`translate(${xOffset} 0)`}>
					<text key={value} fontSize="10" textAnchor="middle" y={6} dominantBaseline="hanging">
						{value}
					</text>
					<line y1={16} y2={22} stroke="black" />
					<line y1={23} y2={height + 23} stroke="#999" />
				</g>
			))}
			<path transform="translate(0 16)" d={`M ${range[0]} 0 v 6 H ${range[1]} v -6`} fill="none" stroke="black" />
		</svg>
	)
}
