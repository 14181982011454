import { ComponentType, PropsWithChildren, createElement } from "react"
import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { useNavigate, useLocation, Link } from "react-router-dom"
import { dispatch } from "../../reducers/RootReducer"
import { actions } from "../../reducers/AppReducer"

export type Props = {
	to: string
	icon?: ComponentType
	divider?: boolean
	showSelected?: boolean
}
export const NavbarLink = ({ to, icon, children, divider = false, showSelected = true }: PropsWithChildren<Props>) => {
	const navigate = useNavigate()
	const location = useLocation()
	const action = () => {
		navigate(to)
		dispatch(actions.setMenu(false))
	}
	return (
		<ListItem button selected={showSelected && location.pathname === to} onClick={action} divider={divider}>
			{icon && <ListItemIcon>{createElement(icon)}</ListItemIcon>}
			<ListItemText>
				<Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
					{children}
				</Link>
			</ListItemText>
		</ListItem>
	)
}
