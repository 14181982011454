import { useMemo } from "react"
import { rgb } from "d3-color"
import { PathLayer, RGBAColor } from "deck.gl"
import { useRoadNetwork, RoadJson } from "./useRoadNetwork"
import { useAgeLayer, useDueLayer } from "./useAgeLayer"
import { useParams } from "react-router-dom"
import { ParamsProps } from "../components/Navbar/Navbar"

export const summerRoadStyle = [
	{ strokeColor: "#d40300" },
	{ strokeColor: "#d40300" },
	{ strokeColor: "#da8b03" },
	// {strokeColor: '#eedb00'},
	{ strokeColor: "#3acb20" },
	{ strokeColor: "#227de5" },
	{ strokeColor: "#6013ac" },
	{ strokeColor: "#6013ac" },
	{ strokeColor: "#000000" },
	// {strokeColor: '#333333'},
	// {strokeColor: '#000000'},
]
export const winterRoadStyle = [
	{ class: "A", strokeColor: "#d40300" },
	{ class: "B", strokeColor: "#da8b03" },
	{ class: "C", strokeColor: "#3acb20" },
	{ class: "D", strokeColor: "#227de5" },
	{ class: "E", strokeColor: "#6013ac" },
	{ class: "F", strokeColor: "#000000" },
]

export const useNetworkLayer = <T extends RoadJson>(id: string, network: T[], getColor: (d: T) => RGBAColor) => {
	return useMemo(
		() =>
			new PathLayer<T>({
				id,
				data: network,
				widthMinPixels: 3,
				widthMaxPixels: 20,
				getWidth: 30,
				getPath: ({ geojson }) => geojson.coordinates,
				getColor,
				pickable: true,
				autoHighlight: true,
			}),
		[id, network, getColor]
	)
}

const getSummerColor = (r: RoadJson) => summerRoadStyle[+r.properties.summerClass - 1]
const getWinterColor = (r: RoadJson) => winterRoadStyle.find(i => i.class === r.properties.winterClass)!

export const useRoadClassLayer = (roadClass: "summer" | "winter") => {
	const network = useRoadNetwork()
	const getStyle = roadClass === "summer" ? getSummerColor : getWinterColor
	return useNetworkLayer(roadClass, network, i => {
		const style = getStyle(i)
		const c = rgb(style.strokeColor)
		return [c.r, c.g, c.b]
	})
}

export const useRoadLayer = () => {
	const { mode, group } = useParams<ParamsProps>()
	const summerLayer = useRoadClassLayer("summer")
	const winterLayer = useRoadClassLayer("winter")
	const ageLayer = useAgeLayer(group!)
	const dueLayer = useDueLayer(group!)
	return group === "winter" ? winterLayer : group === "summer" ? summerLayer : mode === "age" ? ageLayer : dueLayer
}
