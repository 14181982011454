import { format as formatDate } from "date-fns"
import { Forecast } from "./RwisContent"

type Props = {
	forecast?: Forecast | null
}

export const WrittenForecast = ({ forecast }: Props) => {
	if (!forecast) return <div className="forecast-data">Loading...</div>

	const { issue_time, discussion } = forecast
	const { confidence, periods } = discussion

	return (
		<div style={{ fontSize: "12pt", textAlign: "center", margin: "auto" }}>
			<p style={{ margin: "0 auto" }}>
				Forecast posted {formatDate(new Date(issue_time), "HH:mm")}. Confidence {confidence.toLocaleLowerCase()}.
			</p>
			<table style={{ borderCollapse: "collapse", margin: "auto" }}>
				<tbody>
					{periods?.map(({ period, description }, i) => {
						const [weekday, modifier] = period.split(" ")
						return (
							<tr key={period} style={{ backgroundColor: i % 2 ? "#ddd" : "#fff" }}>
								<th style={{ fontFamily: "monospace" }}>
									{weekday.substring(0, 3).toUpperCase()}
									{modifier === "night" ? "2" : "1"}
								</th>
								<td>{description}</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
