import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox } from "@mui/material"
import { actions } from "../../reducers/AppReducer"
import { dispatch, useSelector } from "../../reducers/RootReducer"
import { DirectionalLocationIcon } from "../Icons"

export const FollowMe = () => {
	const centerOnUser = useSelector(state => state.app.centerOnUser)
	const toggleFollow = () => dispatch(actions.toggleCenterOnUser())
	return (
		<ListItem button onClick={toggleFollow}>
			<ListItemIcon>
				<DirectionalLocationIcon />
			</ListItemIcon>
			<ListItemText primary="Follow Me" />
			<ListItemSecondaryAction>
				<Checkbox edge="end" onChange={toggleFollow} checked={centerOnUser} color="primary" />
			</ListItemSecondaryAction>
		</ListItem>
	)
}
