import { IconLayer, Position } from "deck.gl"
import { dispatch } from "../reducers/RootReducer"
import { actions as popupActions } from "../reducers/PopupReducer"
import { IconDefinitionBase } from "@deck.gl/layers/icon-layer/icon-layer"
import { useDrivebcQuery } from "../graphql"
import { DrivebcEventType } from "../components/DriveBcEvent"

export const useDriveBcEvents = (areas: string[]) => {
	return useDrivebcQuery({
		pollInterval: 5 * 60 * 1000,
		variables: { areas },
	})
}

const eventIcons = {
	CONSTRUCTION: {
		url: "/icons/construction.svg",
		width: 480,
		height: 480,
	},
	INCIDENT: {
		url: "/icons/hazard.svg",
		width: 480,
		height: 480,
	},
} as Record<string, IconDefinitionBase & { url: string }>
export const useDriveBcLayer = (visible: boolean) => {
	const { data } = useDriveBcEvents(["all"]) // ["fraser", "terrace", "robson"]
	return new IconLayer<DrivebcEventType>({
		id: "drivebc",
		data: data?.drivebcEvents,
		visible,
		getIcon: ({ eventType }) => eventIcons[eventType as keyof typeof eventIcons] ?? eventIcons.INCIDENT,
		getSize: 48,
		getPosition: ({ position }) => position as Position,
		pickable: true,
		onClick: ({ object }) => dispatch(popupActions.setEvent(object)),
		autoHighlight: true,
	})
}
