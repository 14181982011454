import { PickInfo } from "deck.gl"
import { format } from "date-fns"
import { StationsQuery, ScrsQuery } from "../graphql"
import { DrivebcEventType } from "./DriveBcEvent"
import { RoadJson } from "../maplayers/useRoadNetwork"
import { RestArea, RoadStructure } from "../maplayers/useInfrastructure"
import { CameraMarkerProps } from "../maplayers/useHwyCamLayer"
import { BreadcrumbSegment } from "../maplayers/useAssetBreadcrumbLayer"
import { CurrentVehiclePosition } from "../maplayers/useVehicleLayer"

export type RwisStationType = NonNullable<StationsQuery["rwisStations"]>[number]
export type ScrType = ScrsQuery["scrs"][number]

export type Tooltip = {
	text?: string | undefined
	html?: string | undefined
	className?: string | undefined
	style?: {} | undefined
} | null
export const getTooltip = <D>({ layer, object }: PickInfo<D>): Tooltip => {
	if (!layer?.id || !object) return null
	const { id } = layer
	if (id in layerTooltips) {
		const returnVal = layerTooltips[id](object)
		return typeof returnVal === "string" ? { text: returnVal } : returnVal
	}
	if (id.startsWith("age_") || id.startsWith("due_") || ["summer", "winter", "dashcam"].includes(id)) {
		const returnVal = roadTooltip(object as unknown as RoadJson)
		return typeof returnVal === "string" ? { text: returnVal } : returnVal
	}
	return null
}

const roadTooltip = ({ properties: { rfiHighwayName, rfiHighwayDescription } }: RoadJson) =>
	`${rfiHighwayName} - ${rfiHighwayDescription}`

const compassSuffix = / - (N|S|E|W)$/
const layerTooltips = {
	breadcrumbs: ({ datetime, speed }: BreadcrumbSegment) =>
		`${format(datetime, "h:mm a")}${speed !== null ? ` / ${speed.toFixed(0)} kph` : ""}`,
	rwis: ({ stationName }: RwisStationType) => stationName,
	drivebc: ({ updated, eventType }: DrivebcEventType) =>
		`${eventType} updated ${format(new Date(updated), "MMM dd h:mm a")}`,
	"hwy-cam": ({ cameras }: CameraMarkerProps) => {
		const names = cameras.map(i => i.name)
		const suffixes = names.map(n => compassSuffix.exec(n)).filter(i => i !== null)
		if (names.length !== suffixes.length) return cameras.map(i => i.name).join("\n")
		return `${names[0].substr(0, names[0].length - 4)} ${suffixes.map(s => s![1]).join("/")}`
	},
	self: ({ lat, lng }: { lat: number; lng: number; heading?: number }) => `${lat.toFixed(6)} × ${lng.toFixed(6)}`,
	scrs: (scrs: ScrType[]) =>
		scrs
			.map(i => i.Subject)
			.sort()
			.join("\n"),
	vehicles: ({ asset: { assetCode, licensePlate, model, year }, datetime }: CurrentVehiclePosition) =>
		[assetCode, model && `${year ? year + " " : ""}${model}`, licensePlate, format(datetime, "MMMM d h:mm a")]
			.filter(i => i)
			.join("\n"),
	structures: ({ structureCode, structureName }: RoadStructure) => `${structureCode} - ${structureName}`,
	restareas: ({ restAreaNumber, restAreaName, restAreaClass }: RestArea) =>
		`${restAreaNumber}: ${restAreaName} (${restAreaClass})`,
} as Record<string, (object: any) => Tooltip | string>
