import { useMemo } from "react"
import { IconLayer } from "deck.gl"
import { dispatch, useSelector } from "../reducers/RootReducer"
import { actions } from "../reducers/PopupReducer"
import { useWorkidsQuery, WorkidsQuery } from "../graphql"

export type ActivityCategory = "signs" | "other"
export type WorkID = WorkidsQuery["workids"][number] & { activityCategory: ActivityCategory }

const pollInterval = 60000
const useWorkIdQuery = (days: number) => {
	return useWorkidsQuery({ pollInterval, variables: { days } })
}
export const useWorkIds = (days: number) => {
	const { data } = useWorkIdQuery(days)
	return useMemo(() => {
		if (!data?.workids) return []
		return data.workids.map(
			({ activity, ...workid }) =>
				({
					...workid,
					activityCategory: activity.startsWith("440") || activity.startsWith("501") ? "signs" : "other",
				} as WorkID)
		)
	}, [data?.workids])
}

const otherIcon = {
	url: "/icons/workid.svg",
	width: 448,
	height: 512,
	mask: true,
}
const signIcon = {
	url: "/icons/sign.svg",
	width: 512,
	height: 512,
	mask: true,
}
export const useWorkIdLayer = (days = 90) => {
	const workids = useWorkIds(days)

	const signs = useSelector(state => state.layers.signs)
	const other = useSelector(state => state.layers.otherWorkIds)
	return useMemo(() => {
		const categories: ActivityCategory[] = []
		signs && categories.push("signs")
		other && categories.push("other")
		const data = workids.filter(i => i.location && categories.includes(i.activityCategory))
		return new IconLayer<WorkID>({
			id: "Work IDs",
			data,
			visible: signs || other,
			getIcon: ({ activityCategory }) => (activityCategory === "signs" ? signIcon : otherIcon),
			getSize: 48,
			getColor: [255, 80, 0],
			getPosition: ({ location }) => [location!.lng, location!.lat],
			pickable: true,
			onClick: ({ object }) => dispatch(actions.setWorkId(object)),
			autoHighlight: true,
		})
	}, [signs, other, workids])
}
