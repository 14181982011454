import { useMemo } from "react"
import { addMinutes } from "date-fns"
import { dispatch, useSelector } from "../reducers/RootReducer"
import { actions } from "../reducers/AppReducer"
import { VehicleDialog } from "../components/VehicleDialog"
import { getAngle } from "../utils"
import { IconLayer } from "deck.gl"
import { useAssets } from "../hooks/useAssets"
import { useBreadcrumbs } from "../hooks/useBreadcrumbs"
import { group } from "d3-array"
import { useTargetCrumbs } from "./useAssetBreadcrumbLayer"

export const assetTypes = {
	Pickup: "pickup",
	"Manager Pickup": "pickup",
	"Single Axle Truck": "truck",
	"Tandem Axle Truck": "tandem",
	"Tridam Axle Truck": "tandem",
	Grader: "grader",
	Loader: "loader",
	Backhoe: "tractor",
	Excavator: "tractor",
}

export const ActiveVehicleMarker = () => {
	const activeAsset = useSelector(state => state.app.activeAsset)
	const unitCrumbs = useTargetCrumbs(activeAsset)
	if (!unitCrumbs || unitCrumbs.length === 0) return null
	const crumb = unitCrumbs[unitCrumbs.length - 1]
	return <VehicleDialog {...crumb} asset={activeAsset!} offsetTop={-12} unitController />
}

// this version doesn't contain direction of travel information
// const useLatestPositions2 = () => {
// 	const assets = useAssets()
// 	const { data } = useLatestLocationsQuery({
// 		fetchPolicy: "network-only",
// 		nextFetchPolicy: "cache-only",
// 	})
// 	return useMemo(() => {
// 		if (!assets || !data?.assetLatestLocations) return null
// 		return data.assetLatestLocations.map(({ assetId, datetime, geog, speed }) => ({
// 			asset: assets.get(assetId!),
// 			datetime: new Date(datetime!),
// 			position: [geog!.longitude, geog!.latitude],
// 			speed,
// 		}))
// 	}, [assets, data?.assetLatestLocations])
// }

const useLatestPositions = () => {
	const assets = useAssets()
	const breadcrumbs = useBreadcrumbs()
	return useMemo(() => {
		if (!assets || !breadcrumbs) return []
		const assetCrumbs = group(breadcrumbs, b => b.assetId)
		const positions = Array.from(assetCrumbs.values()).map(crumbs => {
			// assume last crumb is most recent
			const crumb = crumbs[crumbs.length - 1]
			const crumb2 = crumbs[crumbs.length - (crumbs.length > 1 ? 2 : 1)]

			return {
				asset: assets.get(crumb.assetId)!,
				position: crumb.position,
				prevPosition: crumb2.position,
				datetime: new Date(crumb.datetime),
				speed: crumb.speed,
			}
		})
		return positions
	}, [assets, breadcrumbs])
}

export type CurrentVehiclePosition = ReturnType<typeof useLatestPositions>[number]
const STALE_MINUTES = 10
export const useVehicleLayer = (visible: boolean) => {
	const data = useLatestPositions()
	return useMemo(() => {
		const oldThreshold = addMinutes(new Date(), -STALE_MINUTES).getTime()
		return new IconLayer<CurrentVehiclePosition>({
			id: "vehicles",
			data,
			visible,
			getAngle: ({ position, prevPosition }) => getAngle(prevPosition, position),
			getColor: ({ datetime }) => [64, 64, 64, datetime.getTime() < oldThreshold ? 0.4 * 255 : 255],
			getIcon: ({ asset, position, prevPosition }) => ({
				url: `/icons/vehicles/${assetTypes[asset.category as keyof typeof assetTypes] ?? "pickup"}-${
					position[0] - prevPosition[0] > 0 ? "right" : "left"
				}.svg`,
				height: 256,
				width: 256,
				mask: true,
			}),
			getSize: 80,
			// sizeUnits: "meters",
			// sizeMinPixels: 100,
			// sizeMaxPixels: 100,
			// sizeScale: 150,
			getPosition: ({ position }) => position,
			pickable: true,
			onClick: ({ object: { asset } }) => dispatch(actions.setActiveAsset(asset)),
			// onHover: setTooltip,
			autoHighlight: true,
		})
	}, [data, visible])
}

// const setTooltip: LayerInputHandler<CurrentVehiclePosition> = ({ object, x, y }) => {
// 	if (!object) return dispatch(actions.setTooltip(undefined))
// 	const {
// 		asset: { assetCode, licensePlate, model, year },
// 		datetime,
// 	} = object
// 	const text = [
// 		assetCode,
// 		model && `${year ? year + " " : ""}${model}`,
// 		licensePlate,
// 		format(datetime, "MMMM d h:mm a"),
// 	]
// 		.filter(i => i)
// 		.join("\n")
// 	dispatch(actions.setTooltip({ x, y, text }))
// }
