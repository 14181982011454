import { StaticMap } from "react-map-gl"
import { DeckGL } from "@deck.gl/react"
import { useMapLayers } from "./useMapLayers"
import { useSelector } from "../../reducers/RootReducer"
import { ActiveVehicleMarker } from "../../maplayers/useVehicleLayer"
import { getTooltip } from "../getTooltip"
import { TimeSlider } from "../TimeSlider"
import { Tooltip } from "../Tooltip"
import { useViewState } from "./useViewState"
import "mapbox-gl/dist/mapbox-gl.css"

// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// https://github.com/mapbox/mapbox-gl-js/issues/10173
// https://github.com/mapbox/mapbox-gl-js/pull/10219
// https://github.com/mapbox/mapbox-gl-js-docs/pull/461

const mapboxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!
const mapboxUsername = process.env.REACT_APP_MAPBOX_USERNAME!
const mapStyleIds = {
	simple: "ckj7h8m3a3m0j1al7l3vwakpf",
	satellite: "ckjc1cwwm5e2g1amsvkp17ggu",
}

const useMapStyle = () => {
	const layers = useSelector(state => state.layers)
	const mapStyleId = layers.satellite ? mapStyleIds.satellite : mapStyleIds.simple
	return `mapbox://styles/${mapboxUsername}/${mapStyleId}`
}

export type AnimationMeta = ReturnType<typeof useMapLayers>["animation"]

export const AvlMap = () => {
	const [viewState, setViewState] = useViewState()
	const mapStyle = useMapStyle()
	const { animation, layers: mapLayers } = useMapLayers()

	return (
		<>
			<TimeSlider animation={animation} />
			<StaticMap
				height="100%"
				width="100%"
				viewState={viewState}
				mapStyle={mapStyle}
				mapboxApiAccessToken={mapboxAccessToken}
			>
				<DeckGL
					viewState={viewState}
					onViewStateChange={v => setViewState(v.viewState)}
					layers={mapLayers}
					controller
					getTooltip={getTooltip}
					onError={(...props) => console.log(props)}
				/>
				<Tooltip />
				<ActiveVehicleMarker />
			</StaticMap>
		</>
	)
}
