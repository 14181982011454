import { useState, useEffect } from "react"
import { ViewState } from "react-map-gl"
import { useParams } from "react-router-dom"
import { touchUi } from "../../utils"
import { useGps } from "../../hooks/useGps"
import { useSelector } from "../../reducers/RootReducer"
import { usePrevious } from "../../hooks/usePrevious"
import { ParamsProps } from "../Navbar/Navbar"
import { useYards } from "../../maplayers/useInfrastructure"
import serviceAreas from "../Navbar/serviceAreas.json"

const defaultViewState = { latitude: 54.525, longitude: -128.6, zoom: 8 }
export const useViewState = () => {
	const [viewState, setViewState] = useState<ViewState>(defaultViewState)

	// center on first load when on mobile devices, or whenever instructed
	const [firstGps, setFirstGps] = useState(true)
	const centerOnUser = useSelector(state => state.app.centerOnUser)
	const gps = useGps()
	useEffect(() => {
		if (!(gps && (firstGps || centerOnUser))) return
		firstGps && setFirstGps(false)
		// center initially on user when on a touch device
		if (!touchUi) return
		const {
			coords: { latitude, longitude },
		} = gps
		setViewState(state => ({ ...state, latitude, longitude }))
	}, [gps, firstGps, centerOnUser])

	const { area, yard } = useParams<ParamsProps>()
	const oldArea = usePrevious(area)
	useEffect(() => {
		if (!area || area === oldArea) return // not using area or no change
		const locationState = serviceAreas[area as keyof typeof serviceAreas]
		setViewState(state => ({ ...state, ...locationState }))
	}, [area, oldArea])

	const yards = useYards()
	const oldYards = usePrevious(yards)
	const oldYard = usePrevious(yard)
	useEffect(() => {
		if (!yard || (yard === oldYard && yards === oldYards)) return // not using yard or no change
		const yardGeog = yards?.find(y => y.shortName === yard)?.geog
		if (!yardGeog) return
		const { latitude, longitude } = yardGeog
		setViewState(state => ({ ...state, latitude, longitude, zoom: 10 }))
	}, [yard, oldYard, yards, oldYards])

	return [viewState, setViewState] as const
}
