import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
	satellite: false,
	vehicles: true,
	rwis: true,
	cameras: true,
	scrs: true,
	breadcrumbs: false,
	comments: true,
	dashcams: true,
	drivebc: true,
	restareas: true,
	signs: false,
	otherWorkIds: true,
	animation: false,
}

export type Layers = typeof initialState

export const { reducer, actions } = createSlice({
	name: "layers",
	initialState,
	reducers: {
		toggleLayer: (state, { payload }: PayloadAction<keyof Layers>) => {
			state[payload] = !state[payload]
		},
		setLayer: (state, { payload }: PayloadAction<{ layer: keyof Layers; value: boolean }>) => {
			state[payload.layer] = payload.value
		},
	},
})
