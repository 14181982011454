import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from "@mui/material"
import { ComponentType, ReactNode, useState, createElement } from "react"
import { useParams } from "react-router-dom"
import { ParamsProps } from "./Navbar"

export type Props = {
	prefix?: string
	title: string
	icon?: ComponentType
	defaultOpen?: boolean
	children: ReactNode
}
export const MenuSection = ({ prefix, title, icon, children, defaultOpen = false }: Props) => {
	const [open, setOpen] = useState(defaultOpen)
	const { mode } = useParams<ParamsProps>()

	return (
		<>
			<ListItem button selected={prefix === mode} onClick={() => setOpen(!open)}>
				{icon && <ListItemIcon>{createElement(icon)}</ListItemIcon>}
				<ListItemText>{title}</ListItemText>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>
			<Collapse in={open} style={{ marginLeft: "1em" }}>
				<List dense>{children}</List>
			</Collapse>
		</>
	)
}
