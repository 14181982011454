import { useMemo } from "react"
import { addDays, startOfHour } from "date-fns"
import { Line } from "react-chartjs-2"
import "chart.js/auto"
import "chartjs-adapter-date-fns"
import { RwisQuery, useRwisQuery } from "../../graphql"
import { getOptions } from "./RwisChartOptions"
import { WrittenForecast } from "./WrittenForecast"
import { RwisChartLegend } from "./RwisChartLegend"
import { RwisWindHumidity } from "./RwisWindHumidity"
import { useDatasets } from "./useDatasets"
import { useRange } from "./useRange"

export type Station = RwisQuery["rwis_stations"][number]
export type Reading = Station["readings"][number]
export type Forecast = Station["latestForecast"]

// Create a sliding chart scale with the rounded bottom 20 degrees of the data ranges
const useTileOptions = (name: string, range = [0, 20]) =>
	useMemo(() => {
		const options = getOptions()
		options.plugins!.title!.text = name[0].toUpperCase() + name.substring(1)
		const y = options.scales!.temperature!
		const [min, max] = range
		y.min = min
		y.max = max
		return options
	}, [name, range])

type Props = {
	id: string
	name: string
}

export const RwisContent = ({ id, name }: Props) => {
	const { data } = useRwisQuery({
		variables: {
			station: +id,
			start: addDays(startOfHour(new Date()), -1),
			end: addDays(startOfHour(new Date()), 1),
		},
		fetchPolicy: "network-only",
	})
	const station = data?.rwis_stations?.[0]
	const range = useRange(station)
	const tileOptions = useTileOptions(name, range)
	const datasets = useDatasets(station?.readings, station?.latestForecast)

	const current = station?.readings[station?.readings.length - 1]

	if (!station) return null
	return (
		<div style={{ textAlign: "center" }}>
			{datasets?.length ? (
				<>
					<div style={{ height: 400 }}>
						<RwisChartLegend datasets={datasets} />
						<Line data={{ datasets }} options={tileOptions} />
					</div>
					{current && <RwisWindHumidity current={current} />}
					{station?.latestForecast && <WrittenForecast forecast={station.latestForecast} />}
				</>
			) : (
				<p>No data available at this time for {station.station_name}</p>
			)}
		</div>
	)
}
