import { useState } from "react"
import axios from "axios"
import { TextField, Button } from "@mui/material"
import { dispatch } from "../../reducers/RootReducer"
import { actions } from "../../reducers/AppReducer"
import { handleAuthenticationResult, handleLoginToken, msal } from "./msal"
import { ChangeLog } from "./ChangeLog"
import { MicrosoftIcon } from "./MicrosoftIcon"

export const LoginForm = () => {
	const [username, setUsername] = useState("")
	const [password, setPassword] = useState("")

	const tryLocalLogin = async () => {
		try {
			const authResp = await axios.post("https://auth.eag.tools/login", {
				username,
				password,
				// newpassword: newPassword,
			})
			const credentials = handleLoginToken(authResp.data.token) ?? null
			dispatch(actions.login(credentials))
		} catch (err) {
			const { response } = err as any
			const reason = response
				? response.data?.reason ?? `Error code ${response.status}: ${response.statusText}`
				: "Unknown error"
			if (reason === "Password reset must be performed") {
				// setMustChange(true)
			}
			// setError(reason)
		}
	}

	const tryAzureLogin = async () => {
		const result = await msal.loginPopup()
		const credentials = await handleAuthenticationResult(result)
		credentials !== undefined && dispatch(actions.login(credentials))
	}

	const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		tryLocalLogin()
	}

	return (
		<div style={{ maxWidth: "20em", margin: "2em auto", textAlign: "center" }}>
			<img src="/favicon/android-chrome-512x512.png" alt="AVL Logo" style={{ width: "40%", margin: "1em" }} />
			<br />
			<h1 style={{ margin: 0, fontSize: "1.5em" }}>AVL Monitor</h1>
			<ChangeLog />
			<form onSubmit={handleLogin}>
				<TextField
					type="text"
					name="username"
					label="Username"
					value={username}
					onChange={e => setUsername(e.target.value)}
					autoComplete="current-username"
				/>
				<br />
				<TextField
					type="password"
					name="password"
					label="Password"
					value={password}
					onChange={e => setPassword(e.target.value)}
					style={{ margin: "1em auto" }}
					autoComplete="current-password"
				/>
				<br />
				<Button type="submit" variant="contained" color="primary">
					Login
				</Button>
				&nbsp;or&nbsp;
				<Button
					type="button"
					variant="contained"
					color="primary"
					onClick={tryAzureLogin}
					startIcon={<MicrosoftIcon />}
				>
					Microsoft Login
				</Button>
			</form>
		</div>
	)
}
