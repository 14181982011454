import { format } from "date-fns"
import { DrivebcQuery } from "../graphql"

export type DrivebcEventType = DrivebcQuery["drivebcEvents"][number]

type Props = {
	event: DrivebcEventType
}

export const DriveBcEvent = ({ event: { updated, eventType, description } }: Props) => {
	return (
		<div>
			<h1 style={{ margin: 0, textAlign: "center", color: "white", backgroundColor: "#ff6600" }}>{eventType}</h1>
			<h2 style={{ textAlign: "center", margin: "0.25em auto auto auto" }}>
				Last update: {format(new Date(updated), "MMMM dd 'at' h:mm a")}
			</h2>
			<p style={{ maxWidth: "30em", margin: "1em" }}>{description}</p>
		</div>
	)
}
