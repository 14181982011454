import { IconLayer } from "deck.gl"
import { useMemo } from "react"
import { useGps } from "../hooks/useGps"

export const useSelfLayer = () => {
	const location = useGps()
	return useMemo(() => {
		const data = location?.coords
			? [{ lat: location.coords.latitude, lng: location.coords.longitude, heading: location.coords.heading }]
			: []
		return new IconLayer<{ lat: number; lng: number; heading: number | null }>({
			id: "self",
			data,
			getAngle: ({ heading }) => heading || 0,
			getColor: [35, 139, 231],
			getPosition: ({ lng, lat }) => [lng, lat],
			getSize: 48,
			getIcon: () => ({
				url: "/icons/self.svg",
				width: 256,
				height: 256,
				mask: true,
			}),
			pickable: true,
			autoHighlight: true,
		})
	}, [location?.coords])
}
