import { useMemo } from "react"
import { dispatch } from "../../reducers/RootReducer"
import { actions as popupActions } from "../../reducers/PopupReducer"
import { IconLayer } from "deck.gl"
import { useStationsQuery } from "../../graphql"
import { RwisStationType } from "../../components/getTooltip"

const monitoredStations = [
	51129, 51191, 52091, 52092, 52093, 52326, 52391, 52401, 52522, 52591, 54091, 54092, 14091, 15071, 15092, 15122,
	15322, 25221, 25225, 25229, 15093, 15124, 15392, 24225, 15321, 41091, 46094, 46127, 46191, 46182, 42064,
]

export const useRwisLayer = (visible: boolean) => {
	const { data: stations } = useStationsQuery({
		fetchPolicy: "cache-first",
		pollInterval: 24 * 60 * 60 * 1000,
	})
	const data = useMemo(
		() => stations?.rwisStations?.filter(({ id }) => monitoredStations.includes(id)) ?? [],
		[stations?.rwisStations]
	)
	return new IconLayer<RwisStationType>({
		id: "rwis",
		data,
		visible,
		getPosition: ({ geog: { longitude, latitude } }) => [longitude, latitude],
		getSize: 64,
		getIcon: () => ({
			url: "/icons/rwis.svg",
			width: 80,
			height: 160,
		}),
		pickable: true,
		onClick: ({
			object: {
				id,
				stationName: name,
				geog: { longitude, latitude },
			},
		}) => dispatch(popupActions.setRwis({ id: id.toString(), name, position: { lng: longitude, lat: latitude } })),
		autoHighlight: true,
	})
}
