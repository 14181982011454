import {
	LayersIcon,
	SatelliteIcon,
	RwisIcon,
	DriveBcIcon,
	HwyCamIcon,
	ScrIcon,
	DashcamIcon,
	RestAreaIcon,
	WorkIdIcon,
	SignIcon,
} from "../Icons"
import { PlowIcon, MovieIcon } from "../VehicleIcons"
import { LayerToggle } from "./LayerToggle"
import { MenuSection } from "./MenuSection"

export const LayerSettings = () => {
	return (
		<MenuSection title="Layers" icon={LayersIcon} defaultOpen>
			<LayerToggle label="Satellite" icon={SatelliteIcon} name="satellite" />
			<LayerToggle label="Vehicles" icon={PlowIcon} name="vehicles" />
			<LayerToggle label="Animation" icon={MovieIcon} name="animation" />
			{/* <LayerToggle label="Breadcrumbs" icon={WaypointIcon} name="breadcrumbs" /> */}
			<LayerToggle label="RWIS" icon={RwisIcon} name="rwis" />
			<LayerToggle label="DriveBC" icon={DriveBcIcon} name="drivebc" />
			<LayerToggle label="Cameras" icon={HwyCamIcon} name="cameras" />
			<LayerToggle label="SCRs" icon={ScrIcon} name="scrs" />
			<LayerToggle label="Dashcams" icon={DashcamIcon} name="dashcams" />
			<LayerToggle label="Rest Areas" icon={RestAreaIcon} name="restareas" />
			<MenuSection title="Work IDs" icon={WorkIdIcon}>
				<LayerToggle label="Signs" icon={SignIcon} name="signs" />
				<LayerToggle label="Others" icon={WorkIdIcon} name="otherWorkIds" />
			</MenuSection>
			{/* <LayerToggle label="Comments" icon={CommentIcon} name="comments" /> */}
		</MenuSection>
	)
}
