import { useEffect, useMemo, useState } from "react"
import { LineLayer } from "deck.gl"
import { rgb } from "d3-color"
import { ScaleSequential, scaleSequential } from "d3-scale"
import { interpolateViridis } from "d3-scale-chromatic"
import { useBreadcrumbs } from "../hooks/useBreadcrumbs"
import { Asset, useAssets } from "../hooks/useAssets"

const getScale = () => {
	const scale = scaleSequential(interpolateViridis)
	const endTime = Date.now()
	const startTime = endTime - 24 * 60 * 60 * 1000
	scale.domain([startTime, endTime])
	return scale
}

const getLayer = (data: BreadcrumbSegment[], scale: ScaleSequential<string>) => [
	new LineLayer<BreadcrumbSegment>({
		id: "breadcrumb-bg",
		data,
		getWidth: 14,
		getSourcePosition: ({ start }) => start,
		getTargetPosition: ({ end }) => end,
		getColor: [0, 0, 0],
	}),
	new LineLayer<BreadcrumbSegment>({
		id: "breadcrumbs",
		data,
		getWidth: 10,
		getSourcePosition: ({ start }) => start,
		getTargetPosition: ({ end }) => end,
		getColor: ({ datetime }) => {
			const c = rgb(scale(datetime))
			return [c.r, c.g, c.b]
		},
		pickable: true,
		autoHighlight: true,
	}),
]

export type BreadcrumbSegment = {
	start: [number, number]
	end: [number, number]
	datetime: Date
	speed: number | null
}

export const useTargetCrumbs = (asset?: Asset) => {
	const assets = useAssets()
	const breadcrumbs = useBreadcrumbs()
	return useMemo(() => {
		if (!breadcrumbs || !assets || !asset) return
		return breadcrumbs.filter(b => b.assetId === asset.id)
	}, [breadcrumbs, asset, assets])
}
export const useAssetBreadcrumbLayer = (asset?: Asset) => {
	const targetCrumbs = useTargetCrumbs(asset)

	const bcLines = useMemo(() => {
		if (!targetCrumbs) return []
		return targetCrumbs.slice(0, targetCrumbs.length - 1).map((p1, i) => {
			const { position, datetime, speed } = targetCrumbs[i + 1]
			return { start: p1.position, end: position, datetime: new Date(datetime), speed } as BreadcrumbSegment
		})
	}, [targetCrumbs])

	const [layer, setLayer] = useState<LineLayer<BreadcrumbSegment>[]>(getLayer([], getScale()))
	useEffect(() => {
		const updateLayer = () => {
			const scale = getScale()
			setLayer(getLayer(bcLines, scale))
		}
		updateLayer()
		const handle = setInterval(updateLayer, 60000)
		return () => clearInterval(handle)
	}, [bcLines])

	return layer
}
