import { SvgIcon } from "@mui/material"

export const MicrosoftIcon = () => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24">
		<g transform="scale(1.5)">
			<path
				fill="rgb(95.294118%,32.54902%,14.509804%)"
				d="M 7.625 7.625 L 0 7.625 L 0 0 L 7.625 0 Z M 7.625 7.625 "
			/>
			<path
				fill="rgb(50.588235%,73.72549%,2.352941%)"
				d="M 16.046875 7.625 L 8.421875 7.625 L 8.421875 0 L 16.046875 0 Z M 16.046875 7.625 "
			/>
			<path
				fill="rgb(1.960784%,65.098039%,94.117647%)"
				d="M 7.625 16.050781 L 0 16.050781 L 0 8.421875 L 7.625 8.421875 Z M 7.625 16.050781 "
			/>
			<path
				fill="rgb(100%,72.941176%,3.137255%)"
				d="M 16.046875 16.050781 L 8.421875 16.050781 L 8.421875 8.421875 L 16.046875 8.421875 Z M 16.046875 16.050781 "
			/>
		</g>
	</SvgIcon>
)
