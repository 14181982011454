import { useEffect, useMemo, useState } from "react"
import { CircularProgress } from "@mui/material"
import { VideoViewer } from "./VideoViewer"
import { LatLng } from "../utils"
import { useRecentVisits } from "../hooks/useRecentVisits"
// import { useSelector } from "../reducers/RootReducer"

export type Visit<T extends string | Date> = {
	asset_code: string
	datetime: T
}

export type Video = {
	path: string
	startDate: Date
	duration: number
}
export type DashcamVideo = {
	path: string
	assetno: string
	start: string
	end: string
}

export type CameraVisit = Visit<Date> & {
	video: Video
}

// query for complete list of available videos
type VideoDatabase = Record<string, Video[]>
// const allVideos:VideoDatabase= {
// 	'N0148 (Ben)': [
// 		// durations will be pre-calculated in database already and given right away
// 		{path: '20200120_101923', startDate: new Date('2020-01-20T10:19:23'), duration: 9620},
// 		{path: '20200121_074557', startDate: new Date('2020-01-21T07:45:57'), duration: 1420},
// 		{path: '20200121_081900', startDate: new Date('2020-01-21T08:19:00'), duration: 180},
// 		{path: '20200121_111501', startDate: new Date('2020-01-21T11:15:01'), duration: 240},
// 		{path: '20200121_134452', startDate: new Date('2020-01-21T13:44:52'), duration: 780},
// 		{path: '20200121_140506', startDate: new Date('2020-01-21T14:05:06'), duration: 180},
// 		{path: '20200121_140804', startDate: new Date('2020-01-21T14:08:04'), duration: 840},
// 		{path: '20200121_171140', startDate: new Date('2020-01-21T17:11:40'), duration: 740},
// 		{path: '20200123_130125', startDate: new Date('2020-01-23T13:01:25'), duration: 10410},
// 		{path: '20200130_153639', startDate: new Date('2020-01-30T15:36:39'), duration: 4370},
// 		{path: '20200131_214903', startDate: new Date('2020-01-31T22:09:03'), duration: 148*60},
// 		{path: '20200203_162951', startDate: new Date('2020-02-03T16:29:51'), duration: 6870}
// 	]
// }

const getMostRecentVideoVisits = (recentVisits: Visit<Date>[], dashcams: DashcamVideo[]) => {
	const allVideos = dashcams.reduce((vals, i) => {
		const video = {
			path: i.path,
			startDate: new Date(i.start),
			duration: (new Date(i.end).getTime() - new Date(i.start).getTime()) / 1000,
		}
		if (i.assetno in vals) {
			vals[i.assetno].push(video)
		} else {
			vals[i.assetno] = [video]
		}
		return vals
	}, {} as VideoDatabase)

	// only search visits from vehicles known to have cameras
	const dashcamVehicles = new Set(dashcams.map(i => i.assetno))
	const cameraAvailableVisits = recentVisits.filter(i => dashcamVehicles.has(i.asset_code.split(" ")[0]))

	// for each visit, find the applicable video if it exists
	const cameraVisits = cameraAvailableVisits
		.map(i => {
			// get all videos this asset has
			const assetVideos = allVideos[i.asset_code.split(" ")[0] as keyof typeof allVideos]
			// find a video that starts before and ends after the visit time
			const video = assetVideos.find(v => {
				const t = i.datetime.getTime()
				const t0 = v.startDate.getTime()
				const t1 = t0 + v.duration * 1000
				return t0 <= t && t <= t1
			})
			return { ...i, video: video }
			// exclude assets where no video was found
			// (maybe it hasn't synced yet, video was deleted, etc.)
		})
		.filter(i => i.video !== undefined) as CameraVisit[]

	return cameraVisits.sort((a, b) => b.datetime.getTime() - a.datetime.getTime())
}

export type GpsVideoProps = {
	roadId: number
	coords: LatLng
}

// const sessionid = process.env.REACT_APP_SESSIONID // TODO use GraphQL
const useDashcams = (coords: LatLng, hwyNetworkId?: string) => {
	const [dashcams, setDashcams] = useState<DashcamVideo[]>()
	useEffect(() => {
		// const params = {
		// 	sessionid,
		// 	hwyNetworkId,
		// 	lat: coords.lat,
		// 	lng: coords.lng,
		// }
		// axios
		// 	.get<DashcamVideo[]>(`https://avl.eag.tools/api/dashcams?${serialize(params)}`)
		// 	.then(response => setDashcams(response.data))
		setDashcams([])
	}, [coords.lat, coords.lng, hwyNetworkId])
	return dashcams
}

export const GpsVideo = ({ roadId, coords }: GpsVideoProps) => {
	const recentVisits = useRecentVisits(coords.lng, coords.lat, roadId.toString())
	const dashcams = useDashcams(coords, roadId.toString())
	const [visitIndex, setVisitIndex] = useState(0)

	const cameraVisits = useMemo(() => {
		if (!recentVisits || !dashcams) return
		const recentVideoVisits = getMostRecentVideoVisits(recentVisits, dashcams)
		const distinctVideoVisits = recentVideoVisits
			// change to review from oldest to newest
			.reverse()
			.reduce((visits, i) => {
				const lastVisit = visits[visits.length - 1]
				// add only the first timestamp of each consecutive sequence
				// allowing 5m to be part of same sequence
				// wherein I could hit a small side road and return
				if (!lastVisit || i.datetime.getTime() - lastVisit.datetime.getTime() > 5 * 60 * 1000) {
					visits.push(i)
				}
				return visits
			}, [] as CameraVisit[])
			// present as most to least recent
			.reverse()
		return distinctVideoVisits
	}, [recentVisits, dashcams])

	if (cameraVisits === undefined || cameraVisits.length === 0) {
		return (
			<div style={{ width: "360px", textAlign: "center" }}>
				{cameraVisits === undefined ? (
					<CircularProgress size={128} style={{ margin: "4em auto" }} />
				) : (
					<p style={{ margin: "2em auto", fontSize: "2em" }}>No video available</p>
				)}
			</div>
		)
	}
	const visit = cameraVisits[visitIndex]
	// I was starting one minute prior but this seems to be too far back?  Not sure if
	// the video has enough skips to amount of a minute of lag, or if there's another problem
	// const actualStartTime = (visit.datetime.getTime() - visit.video.startDate.getTime()) / 1000
	// Start the video a little early to give context, but
	// brief blips in recording can make it slightly shorter than expectd
	// so remove less time the longer the video goes
	// const startTime = actualStartTime*1.01 - 60
	const startTime = (visit.datetime.getTime() - visit.video.startDate.getTime()) / 1000 - 60
	const prev = visitIndex < cameraVisits.length - 1 ? () => setVisitIndex(visitIndex + 1) : undefined
	const next = visitIndex > 0 ? () => setVisitIndex(visitIndex - 1) : undefined
	return (
		<VideoViewer
			src={`https://avl.eag.tools/dashcams/${visit.video.path}`}
			startTime={Math.max(0, startTime)}
			startSpeed={8}
			width={960}
			prev={prev}
			next={next}
		/>
	)
}
