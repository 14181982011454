import { useParams } from "react-router-dom"
import { useYard } from "../../maplayers/useInfrastructure"
import { useSelector } from "../../reducers/RootReducer"
import { HomeIcon } from "../Icons"
import { ParamsProps } from "./Navbar"
import { NavbarLink } from "./NavbarLink"

export const HomeLink = () => {
	const { home_yard_id } = useSelector(state => state.app.session!.user)
	const userYard = useYard(home_yard_id)
	const { mode, group } = useParams<ParamsProps>()

	return (
		<NavbarLink to={`/yard/${userYard?.shortName ?? "terrace"}/${mode}/${group}`} icon={HomeIcon}>
			Home
		</NavbarLink>
	)
}
