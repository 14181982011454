import { useMemo, useState } from "react"
import { group } from "d3-array"
import { scaleLinear } from "d3-scale"
import { usePatrolHistoryQuery } from "../graphql"
import { Road } from "./RoadChooser"
import { VehicleType } from "./VehicleTypeChooser"
import { Axis } from "./Axis"
import { TimelineRow } from "./TimelineRow"
import { TooltipProps, Tooltip } from "./Tooltip"
import { CircularProgress } from "@mui/material"

export type Period = {
	start: number
	end: number
	assetCodes: Set<string>
}

const usePatrolRecords = (roads: Road[], vehicleType: VehicleType | undefined, start: Date, end: Date | undefined) => {
	const rfis = useMemo(() => roads.map(i => i.rfi!), [roads])
	const categories = vehicleType === VehicleType.ANY ? undefined : ["50", "55"]
	const history = usePatrolHistoryQuery({
		variables: { rfis, categories, start, end },
	})

	const records = history.data?.patrolRecords
	return useMemo(() => {
		if (!records) return
		const sortedRecords = records
			.map(i => ({ ...i, datetime: new Date(i.datetime).getTime() }))
			.sort((a, b) => a.datetime - b.datetime)

		const byRfi = group(sortedRecords, i => i.rfi)
		const roadPeriods = new Map(
			roads.map(({ rfi }) => {
				const times = byRfi.get(rfi!) ?? []
				const continuousTimes = times.reduce((list, i) => {
					const t = i.datetime
					const idx = list.length - 1
					const li = list[idx]
					const last = li?.end
					if (last && t - last < 120_000) {
						li.end = t + 30_000
						li.assetCodes.add(i.assetCode)
					} else list.push({ start: t - 30_000, end: t + 30_000, assetCodes: new Set([i.assetCode]) })
					return list
				}, [] as Period[])
				return [rfi!, continuousTimes]
			})
		)
		return roadPeriods
	}, [records, roads])
}

export type Props = {
	roads: Road[]
	vehicleType: VehicleType
	startTime: Date
	endTime: Date
	width?: number
}

export const ROW_HEIGHT = 16
export const ROW_BUFFER = ROW_HEIGHT * 0.25
export const AXIS_HEIGHT = 24

export const Timeline = ({ roads, vehicleType, startTime, endTime, width = 1000 }: Props) => {
	const records = usePatrolRecords(roads, vehicleType, startTime, endTime)
	const scale = useMemo(
		() => scaleLinear().domain([startTime.getTime(), endTime.getTime()]).range([120, width]),
		[startTime, endTime, width]
	)

	const [hover, setHover] = useState<TooltipProps | null>(null)

	if (!records)
		return (
			<div style={{ margin: "2em auto", textAlign: "center" }}>
				<CircularProgress size="5em" style={{ margin: "auto" }} />
			</div>
		)
	const contentHeight = records.size * (ROW_HEIGHT + ROW_BUFFER) - ROW_BUFFER
	return (
		<>
			<svg viewBox={`0 0 ${width} ${contentHeight + AXIS_HEIGHT}`}>
				<Axis domain={[startTime.getTime(), endTime.getTime()]} range={[120, width]} height={contentHeight} />
				{Array.from(records.entries()).map(([rfi, periods], i) => (
					<TimelineRow key={rfi} {...{ i, rfi, periods, scale, setHover }} />
				))}
			</svg>
			{hover && <Tooltip {...hover} />}
		</>
	)
}
