import { useSelector } from "../reducers/RootReducer"

export const Tooltip = () => {
	const tooltip = useSelector(state => state.app.tooltip)

	if (!tooltip) return null
	return (
		<div style={{ position: "absolute", zIndex: 1, pointerEvents: "none", left: tooltip.x, top: tooltip.y }}>
			{tooltip.text}
		</div>
	)
}
