import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CommentChain } from "../hooks/useComments"

export type CommentState = {
	comments: CommentChain[]
	activeComment?: CommentChain
}

export const sampleComments = [
	{
		id: 1,
		archived: false,
		position: {
			lat: 54.5,
			lng: -128.5,
		},
		comments: [
			{
				id: 1,
				author: "Ben",
				date: new Date("2020-01-15 1:52 PM").toJSON(),
				comment: "This is a test",
			},
			{
				id: 2,
				author: "Connor",
				date: new Date("2020-01-14 3:27 PM").toJSON(),
				comment: "So is this",
			},
		],
	},
]

export const initialState: CommentState = {
	comments: sampleComments,
}

export const { reducer, actions } = createSlice({
	name: "comments",
	initialState,
	reducers: {
		updateComments: (state, { payload }: PayloadAction<CommentChain[]>) => {
			state.comments = payload
		},
		setActiveComment: (state, { payload }: PayloadAction<CommentChain | undefined>) => {
			state.activeComment = payload
		},
	},
})
