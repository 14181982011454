import { format as formatDate } from "date-fns"
import { WorkID } from "./useWorkIds"

type Props = {
	workid: WorkID
}

export const WorkIdDialog = ({ workid }: Props) => {
	return (
		<div style={{ fontSize: "1.2em", margin: "1em" }}>
			<h2 style={{ textAlign: "center", marginTop: 0, fontSize: "1em" }}>
				<span style={{ fontSize: "smaller" }}>
					Work ID {workid.ActionItemId}
					{workid.TaskID && `( Task ${workid.TaskID})`}
				</span>
				<br />
				<span style={{ fontSize: "smaller" }}>
					Last updated {formatDate(new Date(workid.time.substr(0, workid.time.length - 1)), "cccc t")}
				</span>
				<br />
				{workid.activity} ({workid.qty} {workid.uom})
			</h2>
			<p style={{ textAlign: "center" }}>{workid.by}</p>
			{workid.Comments?.split("\n").map((s, i) => (
				<p key={i} style={{ maxWidth: "20em", margin: "auto" }}>
					{s}
				</p>
			))}
		</div>
	)
}
