import { useMemo } from "react"
import { useAssetsQuery, AssetsQuery } from "../graphql"

export type Asset = NonNullable<AssetsQuery["assets"]>[number] & { category: string | null }
export const useAssets = () => {
	const { data } = useAssetsQuery({ fetchPolicy: "cache-first" })

	return useMemo(() => {
		if (!data?.assets && !data?.assetCategories) return null
		const { assets, assetCategories } = data
		const categories = assetCategories!.reduce((map, c) => {
			map[c.prefix] = c.description
			return map
		}, {} as Record<string, string | null>)
		return assets!.reduce((map, { assetCode, ...a }) => {
			map.set(a.id, { ...a, assetCode, category: categories[assetCode.substr(0, 2)!] ?? null })
			return map
		}, new Map<number, Asset>())
	}, [data])
}
