export const serialize = (params: any) => {
	let str: string[] = []
	Object.keys(params).forEach(i => str.push(encodeURIComponent(i) + "=" + encodeURIComponent(params[i])))
	return str.join("&")
}

export const touchUi = "ontouchstart" in window

export type LatLng = { lat: number; lng: number }

export const getAngle = (p1: [number, number], p2: [number, number]) => {
	const angle = Math.atan((p2[1] - p1[1]) / (p2[0] - p1[0]))
	return isNaN(angle) ? 0 : (angle * 180) / Math.PI
}

export const jwtDecode = (jwt: string) => {
	const payload = atob(jwt.split(".")[1])
	return JSON.parse(payload)
}
