import { Button, ButtonGroup } from "@mui/material"
type Props = {
	area: string
	setArea: (area: string) => void
}
export const AreaChooser = ({ area, setArea }: Props) => {
	const pickArea = (area: string) => () => setArea(area)
	return (
		<ButtonGroup disableElevation variant="contained" color="primary">
			<Button variant={area === "26" ? "contained" : "outlined"} onClick={pickArea("26")}>
				26 Skeena
			</Button>
			<Button variant={area === "20" ? "contained" : "outlined"} onClick={pickArea("20")}>
				20 Robson
			</Button>
			<Button variant={area === "07" ? "contained" : "outlined"} onClick={pickArea("07")}>
				07 Fraser
			</Button>
		</ButtonGroup>
	)
}
