export const credentialsKey = "credentials"
export type Profile = {
	username: string
	userid: string
	sessionid: number
	email?: string | null
	employee_no: number
	company_no: number
	fullname: string
	scopes: string[]
	home_yard_id: number
}
export type Credentials = {
	user: Profile
	authToken: string
}
