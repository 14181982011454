import { useCallback, useState } from "react"
import { Layer } from "@deck.gl/core"
import { useVehicleLayer } from "../../maplayers/useVehicleLayer"
import { useAssetBreadcrumbLayer } from "../../maplayers/useAssetBreadcrumbLayer"
import { useSelector } from "../../reducers/RootReducer"
import { useSelfLayer } from "../../maplayers/useSelfLayer"
import { useRwisLayer } from "../../maplayers/rwis/useRwisLayer"
import { useHwyCamLayer } from "../../maplayers/useHwyCamLayer"
import { useDashcamLayer } from "../../maplayers/useDashcamLayer"
import { useScrLayer } from "../../maplayers/useScrLayer"
import { useDriveBcLayer } from "../../maplayers/useDriveBc"
import { useRoadLayer } from "../../maplayers/useRoadLayer"
import { useRestareaLayer, useStructureLayer } from "../../maplayers/useInfrastructure"
import { useWorkIdLayer } from "../../maplayers/useWorkIds"
import { useAnimationLayer, useTrips } from "../../maplayers/useAnimationLayer"

export const useMapLayers = () => {
	const layers = useSelector(state => state.layers)
	const activeAsset = useSelector(state => state.app.activeAsset)
	const trips = useTrips()
	const [pause, setPause] = useState(false)
	const {
		layer: animationLayer,
		startTime,
		time,
		setTime,
	} = useAnimationLayer(layers.animation ? trips : undefined, 1000, pause ? 0 : 24 * 60)
	const setRealTime = useCallback(
		(date: Date) => {
			setTime(date.getTime() / 1000 - (startTime ?? 0))
		},
		[setTime, startTime]
	)
	const realStart = startTime ? new Date(startTime * 1000) : undefined
	const animationTime = realStart ? new Date(realStart.getTime() + time * 1000) : undefined
	return {
		animation: {
			animationTime,
			setRealTime,
			startTime: realStart,
			pause,
			setPause,
		},
		layers: [
			useRoadLayer(),
			useDashcamLayer(layers.dashcams),
			useStructureLayer(),
			useAssetBreadcrumbLayer(activeAsset),
			useRestareaLayer(layers.restareas),
			useHwyCamLayer(layers.cameras),
			useRwisLayer(layers.rwis),
			useDriveBcLayer(layers.drivebc),
			useWorkIdLayer(90),
			useScrLayer(layers.scrs),
			useVehicleLayer(layers.vehicles),
			useSelfLayer(),
			animationLayer,
		]
			.filter(i => i)
			.flat() as Layer<any>[],
	}
}
