import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HwyCam } from "../maplayers/useHwyCamLayer"
import { GpsVideoProps } from "../components/GpsVideo"
import { WorkID } from "../maplayers/useWorkIds"
import { DrivebcEventType } from "../components/DriveBcEvent"
import { ScrType } from "../components/getTooltip"
import rwisList from "../maplayers/rwis.json"

export type RwisStation = typeof rwisList[number]

export type RwisPopup = {
	category: "RWIS"
	station: RwisStation
}

export type HighwayCamPopup = {
	category: "HighwayCams"
	cameras: HwyCam[]
}

export type DashcamPopup = {
	category: "Dashcam"
	video: GpsVideoProps
}

export type DrivebcPopup = {
	category: "DriveBC"
	event: DrivebcEventType
}

export type ScrPopup = {
	category: "SCR"
	scrs: ScrType[]
}

export type WorkIDPopup = {
	category: "WorkID"
	workid: WorkID
}

export type Popup = RwisPopup | HighwayCamPopup | DashcamPopup | DrivebcPopup | ScrPopup | WorkIDPopup | null

export const { reducer, actions } = createSlice({
	name: "Popup",
	initialState: null as Popup,
	reducers: {
		setRwis: (state, { payload }: PayloadAction<RwisStation>) => ({
			category: "RWIS",
			station: payload,
		}),
		setCameras: (state, { payload }: PayloadAction<HwyCam[]>) => ({
			category: "HighwayCams",
			cameras: payload,
		}),
		setVideo: (state, { payload }: PayloadAction<GpsVideoProps>) => ({
			category: "Dashcam",
			video: payload,
		}),
		setEvent: (state, { payload }: PayloadAction<DrivebcEventType>) => ({
			category: "DriveBC",
			event: payload,
		}),
		setScrs: (state, { payload }: PayloadAction<ScrType[]>) => ({ category: "SCR", scrs: payload }),
		setWorkId: (state, { payload }: PayloadAction<WorkID>) => ({ category: "WorkID", workid: payload }),
		clear: state => null,
	},
})

export const isHwyCamPopup = (p: Popup): p is HighwayCamPopup => p?.category === "HighwayCams"
export const isRwisPopup = (p: Popup): p is RwisPopup => p?.category === "RWIS"
export const isDashcamPopup = (p: Popup): p is DashcamPopup => p?.category === "Dashcam"
