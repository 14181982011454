import axios from "axios"
import { Configuration, PublicClientApplication, AuthenticationResult } from "@azure/msal-browser"
import { Profile, credentialsKey } from "../../AvlDatabase"
import { AuthToken } from "./AuthToken"
import { jwtDecode } from "../../utils"

const APP_ID = "81e97870-8ede-4ab5-8fa9-6538ff55996c"
const clientId = process.env.REACT_APP_APP_ID ?? APP_ID

// const tenantId = "22780571-2dad-44b2-aa5f-6769f29c197a" // EAG Azure AD
const tenantId = "organizations"

const msalConfig: Configuration = {
	auth: {
		clientId,
		authority: `https://login.microsoftonline.com/${tenantId}`,
		// clientCapabilities: [],
		// TODO set up logout route to handle this
		// postLogoutRedirectUri: `${window.location.origin}/logout`,
		redirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: "localStorage",
		// storeAuthStateInCookie: true,
	},
}

export const msal = new PublicClientApplication(msalConfig)

export const handleAuthenticationResult = async (result: AuthenticationResult | null) => {
	if (result === null) return
	const { idToken, account } = result
	if (account === null) return
	const headers = { Authorization: `Bearer ${idToken}` }
	const authResp = await axios.post("https://auth.eag.tools/login", undefined, { headers })
	const credentials = handleLoginToken(authResp.data.token) ?? null
	return credentials
}

export const handleLoginToken = (authToken: string) => {
	const resp = jwtDecode(authToken) as AuthToken
	if (!resp) return
	const { uid, sid, sub: username, fullname, scopes, email, employee_no, company_no, yard } = resp
	const user: Profile = {
		userid: uid.toString(),
		sessionid: sid,
		username,
		fullname,
		email,
		scopes,
		employee_no,
		company_no,
		home_yard_id: yard,
	}
	const credentials = { user, authToken }
	localStorage.setItem(credentialsKey, JSON.stringify(credentials))
	return credentials
}
