import { useEffect, useState } from "react"
import { Checkbox, FormControlLabel, TextField } from "@mui/material"
import { DateTimePicker } from "@mui/lab"
import { VehicleType, VehicleTypeChooser } from "./VehicleTypeChooser"
import { AreaChooser } from "./AreaChooser"
import { Road, RoadChooser } from "./RoadChooser"
import { Timeline } from "./Timeline"
import { addWeeks, endOfDay, startOfDay } from "date-fns"

export const PatrolTimeline = ({ refreshInterval = 60 * 10 * 1000 }) => {
	const [area, setArea] = useState("26")
	const [roads, setRoads] = useState<Road[]>([])
	const [vehicleType, setVehicleType] = useState<VehicleType>(VehicleType.ANY)
	const [startTime, setStartTime] = useState(addWeeks(startOfDay(new Date()), -1))
	const [endTime, setEndTime] = useState(endOfDay(new Date()))
	const [refresh, setRefresh] = useState(true)

	useEffect(() => {
		const handle = setInterval(() => {
			setStartTime(t => new Date(t.getTime() + refreshInterval))
			setEndTime(t => new Date(t.getTime() + refreshInterval))
		}, refreshInterval)
		return () => clearInterval(handle)
	}, [refresh, refreshInterval])

	return (
		<div style={{ margin: "1em", width: "100%" }}>
			<header style={{ textAlign: "center" }}>
				<h1 style={{ margin: 0 }}>Patrol Timeline</h1>
				<AreaChooser {...{ area, setArea }} />
				&nbsp;
				<VehicleTypeChooser {...{ vehicleType, setVehicleType }} />
				<br />
				<DateTimePicker
					ampm={false}
					value={startTime}
					onChange={e => setStartTime(e as Date)}
					renderInput={params => <TextField label="Start" {...params} />}
				/>
				<DateTimePicker
					ampm={false}
					value={endTime}
					onChange={e => setEndTime(e as Date)}
					renderInput={params => <TextField label="End" {...params} />}
				/>
				<FormControlLabel
					style={{ marginTop: "0.5em" }}
					control={
						<Checkbox checked={refresh} onChange={() => setRefresh(r => !r)} color="primary" name="autorefresh" />
					}
					label="Auto-Refresh"
				/>
			</header>
			<RoadChooser serviceArea={area} {...{ roads, setRoads }} />
			<Timeline {...{ roads, vehicleType, startTime, endTime }} />
		</div>
	)
}
