import { useEffect, useMemo } from "react"
import { TextField } from "@mui/material"
import { Autocomplete } from "@mui/lab"
import { group } from "d3-array"
import { usePatrolRoadsQuery, PatrolRoadsQuery } from "../graphql"

export type Road = NonNullable<PatrolRoadsQuery["patrolRoads"]>[number]

const useRoads = () => {
	const result = usePatrolRoadsQuery()
	const roadList = result.data?.patrolRoads
	const roadsByServiceArea = useMemo(() => {
		if (!roadList) return
		return group(roadList, r => r.serviceArea)
	}, [roadList])
	return { roadList, roadsByServiceArea }
}

export type Props = {
	serviceArea: string
	roads: Road[]
	setRoads: (roads: Road[]) => void
}
export const RoadChooser = ({ serviceArea, roads, setRoads }: Props) => {
	const { roadsByServiceArea } = useRoads()
	const availableRoads = roadsByServiceArea?.get(serviceArea)

	useEffect(() => {
		setRoads((availableRoads ?? []).filter(i => i.lengthM! >= 3000))
	}, [availableRoads, setRoads])

	return (
		<Autocomplete
			style={{ marginTop: "1em" }}
			multiple
			options={availableRoads ?? []}
			groupBy={i => i.winterClass!}
			getOptionLabel={i => `${i.rfi}: ${i.roadName}`}
			renderInput={params => <TextField {...params} label="Roads" variant="outlined" />}
			value={roads}
			onChange={(e, v) => setRoads(v)}
		/>
	)
}
