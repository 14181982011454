import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { store } from "./reducers/RootReducer"
import { LoginExperience } from "./components/Login/LoginExperience"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
	<Provider store={store}>
		<LoginExperience />
	</Provider>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
