import { useMemo } from "react"
import { csvParse } from "d3-dsv"
import { useBreadcrumbsQuery } from "../graphql"

export const useBreadcrumbs = (since = "1 day", pollInterval = 60 * 1000, assetCode?: string) => {
	const { data } = useBreadcrumbsQuery({
		pollInterval,
		variables: { since, assetCode },
	})
	return useMemo(() => {
		if (!data?.breadcrumbs) return []
		return csvParse<"assetId" | "datetime" | "lng" | "lat" | "speed">(data.breadcrumbs).map(
			({ assetId, datetime, lng, lat, speed }) => ({
				datetime: new Date(datetime!),
				position: [+lng!, +lat!] as [number, number],
				speed: speed ? +speed : null,
				assetId: +assetId!,
			})
		)
	}, [data?.breadcrumbs])
}
