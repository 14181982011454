import { useParams } from "react-router-dom"
import { List } from "@mui/material"
import { CategoryIcon, LocationIcon, ChecklistIcon } from "../Icons"
import { touchUi } from "../../utils"
import { NavbarLink } from "./NavbarLink"
import { MenuSection } from "./MenuSection"
import { ServiceAreaLocator } from "./ServiceAreaLocator"
import { Legend } from "./Legend"
import { LayerSettings } from "./LayerSettings"
import { FollowMe } from "./FollowMe"
import { LogoutButton } from "./LogoutButton"
import { PatrolAgeOptions } from "./PatrolAgeOptions"
import { PatrolDueOptions } from "./PatrolDueOptions"
import { HomeLink } from "./HomeLink"

export type ParamsProps = { yard?: string; area?: string; mode: string; group: string }

export const Navbar = () => {
	const { area, yard } = useParams<ParamsProps>()
	const prefix = area ? `/area/${area}` : `/yard/${yard}`

	return (
		<div>
			<List>
				{touchUi && <FollowMe />}

				<MenuSection title="Navigation" icon={LocationIcon}>
					<HomeLink />
					<ServiceAreaLocator />
				</MenuSection>

				<NavbarLink to="/patrols" icon={ChecklistIcon}>
					Patrol Timeline
				</NavbarLink>

				<PatrolAgeOptions prefix={prefix} />
				<PatrolDueOptions prefix={prefix} />
				<LayerSettings />

				<NavbarLink to={`${prefix}/class/summer`} icon={CategoryIcon}>
					Summer Class
				</NavbarLink>
				<NavbarLink to={`${prefix}/class/winter`} icon={CategoryIcon}>
					Winter Class
				</NavbarLink>
			</List>

			<Legend />
			<LogoutButton />
		</div>
	)
}
