import { useMemo } from "react"
import { useVisitsQuery } from "../graphql"

export const useRecentVisits = (longitude: number, latitude: number, roadClassId?: string) => {
	const { data } = useVisitsQuery({
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-only",
		variables: {
			longitude,
			latitude,
			roadClassId,
		},
	})
	return useMemo(
		() => data?.recentVisits.map(({ asset_code, datetime }) => ({ asset_code, datetime: new Date(datetime) })),
		[data]
	)
}
