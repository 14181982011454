import { dispatch } from "../reducers/RootReducer"
import { actions } from "../reducers/PopupReducer"
import { PathLayer } from "deck.gl"
import { RoadJson, useRoadNetwork } from "./useRoadNetwork"

// transparent layer extending beyond thickness of road to give easily clickable area
export const useDashcamLayer = (visible: boolean) => {
	const network = useRoadNetwork()
	return new PathLayer<RoadJson>({
		id: "dashcam",
		data: Object.values(network),
		visible,
		getColor: [0, 0, 0, 0],
		widthMinPixels: 20,
		widthMaxPixels: 100,
		getWidth: 120,
		getPath: ({ geojson }) => geojson.coordinates,
		pickable: true,
		onClick: ({
			object: {
				properties: { id: roadId },
			},
			coordinate,
		}) => {
			const [lng, lat] = coordinate as [number, number]
			dispatch(actions.setVideo({ roadId, coords: { lng, lat } }))
		},
		autoHighlight: true,
	})
}
