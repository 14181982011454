import { ComponentType, createElement, useMemo } from "react"
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Checkbox } from "@mui/material"
import { useSelector, dispatch } from "../../reducers/RootReducer"
import { Layers, actions as layerActions } from "../../reducers/LayersReducer"

export type Props = {
	label: string
	name: keyof Layers
	icon?: ComponentType
}

export const LayerToggle = ({ label, name, icon }: Props) => {
	const layerActive = useSelector(state => state.layers[name])
	return useMemo(() => {
		const toggle = () => dispatch(layerActions.toggleLayer(name))
		return (
			<ListItem button onClick={toggle}>
				{icon !== undefined && <ListItemIcon>{createElement(icon)}</ListItemIcon>}
				<ListItemText primary={label} />
				<ListItemSecondaryAction>
					<Checkbox edge="end" onChange={toggle} checked={layerActive} color="primary" />
				</ListItemSecondaryAction>
			</ListItem>
		)
	}, [label, name, icon, layerActive])
}
