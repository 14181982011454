import { ListItem, ListItemIcon, ListItemText } from "@mui/material"
import { credentialsKey } from "../../AvlDatabase"
import { actions } from "../../reducers/AppReducer"
import { dispatch, useSelector } from "../../reducers/RootReducer"
import { LogoutIcon } from "../Icons"
import { msal } from "../Login/msal"

export const LogoutButton = () => {
	const { username } = useSelector(state => state.app.session!.user)
	const [name, domain] = username.split("@")
	const handleLogout = async () => {
		localStorage.removeItem(credentialsKey)
		if (["eac.bc.ca", "emilanderson.ca"].includes(domain!)) await msal.logoutPopup()
		dispatch(actions.logout())
	}
	return (
		<ListItem button onClick={handleLogout}>
			<ListItemIcon>
				<LogoutIcon />
			</ListItemIcon>
			<ListItemText>Logout {name}</ListItemText>
		</ListItem>
	)
}
