import { useMemo } from "react"
import { ScrType } from "../components/getTooltip"
import { useScrsQuery } from "../graphql"

const formatScrDescription = (s: string) => (s.startsWith("===========") ? s.split("\n").slice(6).join("\n") : s)

const pollInterval = 15 * 60 * 1000
export const useSCRs = () => {
	const { data } = useScrsQuery({ pollInterval, variables: { days: 2 } })
	return useMemo(() => {
		return data?.scrs
			.map(
				({ IssueDescription, ...scr }) =>
					({
						...scr,
						IssueDescription: formatScrDescription(IssueDescription),
					} as ScrType)
			)
			?.sort((a, b) => b.LastModifiedAt.localeCompare(a.LastModifiedAt))
	}, [data?.scrs])
}
