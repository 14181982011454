import { useParams } from "react-router-dom"
import { TerraceIcon, ChilliwackIcon, RobsonIcon } from "../Icons"
import { ParamsProps } from "./Navbar"
import { NavbarLink } from "./NavbarLink"
import serviceAreas from "./serviceAreas.json"

const icons = { "26": TerraceIcon, "07": ChilliwackIcon, "20": RobsonIcon }

export const ServiceAreaLocator = () => {
	const { mode, group } = useParams<ParamsProps>()
	return (
		<>
			{Object.keys(serviceAreas)
				.sort()
				.map(a => (
					<NavbarLink
						key={a}
						to={`/area/${a}/${mode}/${group}`}
						icon={icons[a as keyof typeof icons]}
						showSelected={false}
					>
						Service&nbsp;Area&nbsp;{a}
					</NavbarLink>
				))}
		</>
	)
}
