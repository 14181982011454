import { ListItem, ListItemText } from "@mui/material"
import { LegendIcon } from "./LegendIcon"
import { summerRoadStyle, winterRoadStyle } from "../../maplayers/useRoadLayer"
import ageColors from "../ageColors.json"

export type Props = {
	color: string
	label: string
}
export const LegendLineEntry = ({ color, label }: Props) => (
	<ListItem>
		<LegendIcon color={color} />
		<ListItemText>{label}</ListItemText>
	</ListItem>
)

export const summerClassLegend = summerRoadStyle
	.slice(1, summerRoadStyle.length - 3) // we don't have class 1, combine 6/7 manually and don't care about class 8
	.map((color, i) => <LegendLineEntry key={i} color={color.strokeColor} label={`Class ${i + 2}`} />)
	.concat([<LegendLineEntry key={5} color={summerRoadStyle[5].strokeColor} label={"Class 6/7"} />])

export const winterClassLegend = winterRoadStyle
	.slice(0, winterRoadStyle.length - 1)
	.map(i => <LegendLineEntry key={i.class} color={i.strokeColor} label={`Class ${i.class}`} />)

export const ageLegend = ageColors
	.slice(1, ageColors.length - 1) // not all legends require <0 or infinite options
	.map(({ cutoff, color }, i) => (
		<LegendLineEntry
			key={i}
			color={color}
			label={cutoff < 24 ? `<${cutoff}h` : cutoff < 10000 ? `<${cutoff / 24}d` : "∞"}
		/>
	))

export const nowAgeLegend = <LegendLineEntry color={ageColors[0].color} label="<0h" />
// const infiniteAgeLegend = <ListItem>
// 	<HighContrastIcon color={ageColors[ageColors.length-1].color} />
// 	<ListItemText><span style={{fontSize: '1.5em', lineHeight: '1em'}}>∞</span></ListItemText>
// </ListItem>
