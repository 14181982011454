import { useMemo } from "react"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { useSelector } from "../reducers/RootReducer"

export const GraphProvider: React.FC = ({ children }) => {
	const authToken = useSelector(state => state.app.session?.authToken)
	const client = useMemo(() => {
		const headers = authToken ? { Authorization: `Bearer ${authToken}` } : undefined
		return new ApolloClient({ cache: new InMemoryCache(), uri: "https://hmm.eag.tools/graphql", headers })
	}, [authToken])
	return <ApolloProvider client={client}>{children}</ApolloProvider>
}
