import { ListItemIcon, SvgIcon } from "@mui/material"

export type Props = {
	color: string
}
export const LegendIcon = ({ color }: Props) => {
	return (
		<ListItemIcon>
			<SvgIcon viewBox="0 0 1 1">
				<rect width={1} height={1} color={color} />
			</SvgIcon>
		</ListItemIcon>
	)
}
