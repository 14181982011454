import { TimeLimitIcon, SunIcon, CloudSunIcon, SnowflakeIcon, RaindropsIcon } from "../Icons"
import { MenuSection } from "./MenuSection"
import { NavbarLink } from "./NavbarLink"

export type Props = {
	prefix: string
}
export const PatrolAgeOptions = ({ prefix }: Props) => {
	return (
		<MenuSection title="Patrol Due" prefix="due" icon={TimeLimitIcon}>
			<NavbarLink to={`${prefix}/due/normal`} icon={SunIcon}>
				Normal
			</NavbarLink>
			<NavbarLink to={`${prefix}/due/prestorm`} icon={CloudSunIcon}>
				Pre-Storm
			</NavbarLink>
			<NavbarLink to={`${prefix}/due/storm`} icon={SnowflakeIcon}>
				Storm
			</NavbarLink>
			<NavbarLink to={`${prefix}/due/flood`} icon={RaindropsIcon}>
				Flood
			</NavbarLink>
		</MenuSection>
	)
}
