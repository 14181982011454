import { HwyCam } from "../maplayers/useHwyCamLayer"

export type Props = {
	cameras: HwyCam[]
}
export const CameraContent = ({ cameras }: Props) => {
	return (
		<ol style={{ textAlign: "center", listStyle: "none", margin: 0, padding: 0 }}>
			{cameras.map(i => (
				<li key={i.id}>
					<img
						src={`https://images.drivebc.ca/bchighwaycam/pub/cameras/${i.id}.jpg`}
						alt={i.description}
						style={{ width: "100%" }}
					/>
				</li>
			))}
		</ol>
	)
}
